<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
//import Multiselect from "vue-multiselect";
//import CKEditor from "@ckeditor/ckeditor5-vue";
//import io from "socket.io-client";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { apiRequest } from "@/helpers/api-call";
// import { format } from "date-fns";
import router from "@/router/index.js";
import gsap from 'gsap';
//import Vue from "vue";

/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    //Multiselect,
    //ckeditor: CKEditor.component,
  },
  data() {
    return {
      title: "TOUTES COURSES",
      items: [
        {
          text: "Dashboard",
        },
        {
          text: "TOUTES COURSES",
          active: true,
        },
      ],
      url_link: "",
      userTypeSelect: "",
      messageSelect: null,
      mediaSelect: null,
      messageContent: [
        {
          sujet: "",
          message: "",
          userId: "",
        },
      ],
      value: null,
      value1: null,
      options: ["Sexe", "Taille", "Vehicule", "je vois"],
      idUser: 0,
      processingRide: [],
      dataUserD: [],
      rowDataProcessingRide: 0,
      processingRideTotal: "",
      totalTableRow: 1,
      changeCurrent: 1,
      changePerPage: 100,
      currentPage: 1,
      currentPageD: 1,
      currentPageF: 1,
      currentPageC: 1,
      perPage: 500,
      perPageD: 500,
      perPageF: 500,
      perPageC: 500,
      pageOptions: [100, 250, 500],
      pageOptionsD: [100, 250, 500],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      paginatedDate: [],
      fields: [
      { key: "planedDate", sortable: true, label: "Planifiée pour" },
        { key: "driver", sortable: true, label: "Asso" },
        { key: "driverPhone", sortable: true, label: "Tel Asso" },
        { key: "carType", sortable: true, label: "Type de course" },
        { key: "passenger", sortable: true, label: "Passager" },
        { key: "passengerPhone", sortable: true, label: "Tel Passager" },
        { key: "startTime", sortable: true, label: "Date de début" },
        { key: "endTime", sortable: true, label: "Date de Fin" },
        { key: "startPlace", sortable: true, label: "Lieu de départ" },
        { key: "endPlace", sortable: true, label: "Lieu d'arrivé" },
        { key: "distance", sortable: true, label: "Distance (Km)" },
        { key: "driverCost", sortable: true, label: "Prix Asso" },
        { key: "commission", sortable: true, label: "Commission" },
        { key: "rideFinalCost", sortable: true, label: "Prix Client" },
        { key: "waitingStart", sortable: true, label: "Debut d'attente" },
        { key: "waitingDelay", sortable: true, label: "Durée d'attente" },
        { key: "statut", sortable: true, label: "Statut" },
        { key: "suivi", sortable: true, label: "Position en direct" },

        { key: "action", label: "Actions" },
      ],
      fieldsFinished: [
        { key: "driver", sortable: true, label: "Asso" },
        { key: "driverPhone", sortable: true, label: "Tel Asso" },
        { key: "carType", sortable: true, label: "Type de course" },
        { key: "passenger", sortable: true, label: "Passager" },
        { key: "passengerPhone", sortable: true, label: "Tel Passager" },
        { key: "startTime", sortable: true, label: "Date de début" },
        { key: "endTime", sortable: true, label: "Date de Fin" },
        { key: "startPlace", sortable: true, label: "Lieu de départ" },
        { key: "endPlace", sortable: true, label: "Lieu d'arrivé" },
        { key: "distance", sortable: true, label: "Distance (Km)" },
        { key: "driverCost", sortable: true, label: "Prix Asso" },
        { key: "commission", sortable: true, label: "Commission" },
        { key: "rideFinalCost", sortable: true, label: "Prix Client" },
        { key: "waitingStart", sortable: true, label: "Debut d'attente" },
        { key: "waitingDelay", sortable: true, label: "Durée d'attente" },
        { key: "pause", sortable: true, label: "Temps HLP" },
        { key: "pauseDistance", sortable: true, label: "Distance HLP (Km)" },
        { key: "pauseAmount", sortable: true, label: "Montant  HLP" },


        // { key: "rideRequestTime", sortable: true, label: "Date de course" },

        // { key: "rideEstimCost", sortable: true, label: "Prix Estimé" },

        { key: "statut", sortable: true, label: "Statut" },
        // { key: "suivi", label: "Position en direct" },

      ],
      fieldsStart: [
      { key: "planedDate", sortable: true, label: "Planifiée pour" },
        { key: "passenger", sortable: true, label: "Passager" },
        
        { key: "passengerPhone", sortable: true, label: "Téléphone" },
        { key: "lieuDepart", sortable: true, label: "Départ" },
        { key: "lieuArrive", sortable: true, label: "Destination" },
        { key: "email", sortable: true, label: "Email" },
        //{ key: "type", sortable: true, label: "Type" },
        { key: "requestDate", sortable: true, label: "Date" },
        { key: "statut", sortable: true, label: "Statut" },

        // { key: "endDa", sortable: true, label: "Date de début" },
        // { key: "dateIns", sortable: true, label: "Inscription" },
        //{ key: "manuel", sortable: true, label: "Manuel" },
        { key: "action", label: "Actions" },
      ],
      fieldsC: [
        { key: "motif", sortable: true, label: "Motif" },
        { key: "cancelDate", sortable: true, label: "Annulé le" },
        { key: "driver", sortable: true, label: "Asso" },
        { key: "driverPhone", sortable: true, label: "Tel Asso" },
        { key: "carType", sortable: true, label: "Type de course" },
        { key: "passenger", sortable: true, label: "Passager" },
        { key: "passengerPhone", sortable: true, label: "Tel Passager" },
        // { key: "startTime", sortable: true, label: "Date de début" },
        // { key: "endTime", sortable: true, label: "Date de Fin" },
        { key: "startPlace", sortable: true, label: "Lieu de départ" },
        { key: "endPlace", sortable: true, label: "Lieu d'arrivé" },
        { key: "distance", sortable: true, label: "Distance (Km)" },
        { key: "driverCost", sortable: true, label: "Prix Asso" },
        { key: "penalite", sortable: true, label: "Pénalité" },
        { key: "rideFinalCost", sortable: true, label: "Prix Client" },
        // { key: "waitingStart", sortable: true, label: "Debut d'attente" },
        // { key: "waitingDelay", sortable: true, label: "Durée d'attente" },
        { key: "statut", sortable: true, label: "Statut" },
      ],
      showDisable: false,
      startValue: 1,
      endValue: 500,
      totalValue: "0",
      showDelete: false,
      showEdit: false,
      showSucess: false,
      showEchec: false,
      showSucessEdit: false,
      showEchecEdit: false,
      userToUse: 0,


      userTypeLocal: null,
      showMessage: false,
      showMessageMail: false,
      editor: ClassicEditor,
      editorData: "",
      deleteModal: false,
      userChargeLoader: false,
      newDataUser: [],
      newStart: false,
      recherche: "",
      activeConducteur: false,
      onlyConducteur: [],
      totalRideInProcess: 0,
      completedRide: [],
      totalRideCompleted: 0,
      showMap: false,
      map: null,
      markerLatLng: { lat: 6.3633, lng: 2.4300 },
      center: { lat: 6.3633, lng: 2.4300 },
      zoom: 20,
      markers: [

      ],
      marker: {
        position: {},
        path: []
      },
      icon: null,
      lat: 6.3633,
      lng: 2.4300,
      socket: null,
      selectedRideId: null,
      markerPosition: { lat: 0, lng: 0 },
      markerPositionSaved: { lat: 0, lng: 0 }, // Initial position of the marker
      isDriver: true, // Assuming driver by default
      carIcon: { url: require('@/assets/images/placeholders/basicIconOn.png'), scaledSize: { width: 30, height: 57 } },
      vipIcon: { url: require('@/assets/images/placeholders/vipIcon.png'), scaledSize: { width: 30, height: 57 } },
      zemIcon: { url: require('@/assets/images/placeholders/zemIcon.png'), scaledSize: { width: 30, height: 57 } },
      basicIcon: { url: require('@/assets/images/placeholders/basicIcon.png'), scaledSize: { width: 30, height: 57 } },
      comfortIcon: { url: require('@/assets/images/placeholders/comfortIcon.png'), scaledSize: { width: 30, height: 57 } },
      tryIcon: { url: require('@/assets/images/placeholders/tryIcon.png'), scaledSize: { width: 30, height: 57 } },
      ecoIcon: { url: require('@/assets/images/placeholders/ecoIcon.png'), scaledSize: { width: 30, height: 57 } },
      //import carIcon from '@/assets/images/placeholders/car-icon.png';
      requestData: [],
      requestDataD: [],
      rowsrequestData: 0,
      rowsrequestDataD: 0,
      canceledRequestData: [],
      rowsCanceledRequestData: null,
      totalCanceledRows: 0,
      positionTrackingInterval: null,
      stopModal: false,
      startModal:false,
      cancelModal: false,
      pauseModal: false,
      courseId: null,

      markersEncours: [
      ],
      markersLive: [],


    };
  },
  computed: {


    /**
     * Total no. of records
     */
    rows() {
      return this.processingRide.length;
    },
    rowsinactif() {
      return this.dataUserD.length;
    },
  },

  mounted() {

    this.init();
    this.completedRideMet();
    this.pendingRequest();
    this.canceledRequest();
    this.fetchLivePositionsInterval = setInterval(this.liveDriver, 25000);

    //this.fetchPositionsInterval = setInterval(this.fetchDriverPositions, 20000);
    //this.trackPosition()



  },


  methods: {

    async liveDriver() {

      try {
        const response = await apiRequest('GET', 'realtime-conducteurs');
        if (response) {

          if (response.status == 200) {
            this.markersLive = []
            console.log("driver live", response.data)
            response.data.forEach(
              conducteur => {
                var checkedIcon = '';


                if (conducteur.type == "VIP") {
                  checkedIcon = this.vipIcon

                } else if (conducteur.type == "BASIC") {
                  checkedIcon = this.basicIcon

                }
                else if (conducteur.type == "CONFORT") {
                  checkedIcon = this.comfortIcon

                } else if (conducteur.type == "ZEM") {
                  checkedIcon = this.zemIcon

                } else if (conducteur.type == "TRICYCLE") {
                  checkedIcon = this.tryIcon
                }else if (conducteur.type == "ECO") {
                  checkedIcon = this.ecoIcon
                }

                //console.log("conducteur", conducteur);
                const marker = {
                  position: { lat: conducteur.latitude, lng: conducteur.longitude }, // Initial position
                  driverId: conducteur.id, // Assuming there's a unique driver ID
                  userId: conducteur.user.id,
                  path: [],
                  type: conducteur.type,
                  icon: checkedIcon,
                  label: conducteur.name,
                  name: conducteur.user.personne.lastname + " " + conducteur.user.personne.firstname,
                  city: conducteur.user.personne.workPlaceName,
                  telephone: (conducteur.user.personne.tel && conducteur.user.personne.indicatifTel) ? conducteur.user.personne.indicatifTel + "  " + conducteur.user.personne.tel : 'Aucun nom trouvé',
                  // Other marker properties if needed
                };


                if (!this.markersLive.includes(marker.driverId)) {
                  this.markersLive.push(marker);
                }
                console.log("all driver live", this.markersLive)

              }

            )


          }

        }

      } catch (error) {

        console.log("erreur ", error)

      }

    },

    goToPassengerProfil(id) {
      router.push(`/user/user_info/${id}`)
    },

    async pendingRequest() {
      //Chargement des données
      const data = await apiRequest("GET", "courses-attentes", undefined, false);
      console.log("courses en attentes", data)
      if (data.data && data.data.courses && data.data.total !== undefined) {
        const sortedData = data.data.courses.sort((a, b) => b.id - a.id);
        const formattedTable = sortedData.map((ride) => {


          return {
            id: ride.id ? ride.id : 0,
            passengerId: ride.passager.id ? ride.passager.id : 0,
            passenger: ride.passager.personne ? ride.passager.personne.lastname + " " + ride.passager.personne.firstname : "Passager  Zémitcha ",
            passengerPhone: ride.passager ? ride.passager.personne.indicatifTel + ride.passager.personne.tel : "Pas de numéro",
            lieuDepart: ride.lieuDepart ? ride.lieuDepart : "lieu inconnu",
            lieuArrive: ride.lieuArrive ? ride.lieuArrive : "Destination inconnu",
            distance: ride.distance,
            requestDate: ride.dateCreation ? new Date(ride.dateCreation).toLocaleDateString("Fr-fr") + ' ' + new Date(ride.dateCreation).toLocaleTimeString("Fr-fr", "HH:mm") : "Aucune date trouvée",
            email: ride.passager.personne.email,
            statut: ride.statut || "Indisponible",
          };
        }).sort((a, b) => b.id - a.id);
        this.requestData = formattedTable;
        this.rowsrequestData = formattedTable.length;
        //this.totalRows = formattedTable.length;
        this.totalRows = data.data.total;
      }
    },

    //  GET /courses-annulee

    async canceledRequest() {
      //Chargement des données
      const response = await apiRequest("GET", "courses-annulee", false);
      console.log("courses annulée", response.data)
      if (response && response.status == 200) {
        const sortedData = response.data.courses.sort((a, b) => b.id - a.id);
        const formattedTable = sortedData.map((ride) => {

          if (ride.debutAttente && ride.dateHeureDepart) {

            let startWaiting = new Date(ride.debutAttente)
            let endWaiting = new Date(ride.dateHeureDepart)
            let startWaitingTimesStamp = startWaiting.getTime();
            let endWaitingTimesStamp = endWaiting.getTime();
            const elapsedTime = endWaitingTimesStamp - startWaitingTimesStamp;
            var elapsedTimeInMinute = Math.ceil(elapsedTime / (1000 * 60))

          }
          return {
            id: ride.id,
            // rideEstimCost: ride.prixEstime,

            rideFinalCost: ride.prixFinal,
            driverId: ride.conducteur ? ride.conducteur.id : 0,
            passengerId: ride.passager ? ride.passager.id : 0,
            passenger: ride.passager ? ride.passager.personne.lastname + " " + ride.passager.personne.firstname : "Passager  Zémitcha ",
            passengerPhone: ride.passager ? (ride.passager.personne.indicatifTel + ride.passager.personne.tel || "Pas de numéro") : "Pas de numéro",
            driver: ride.conducteur ? ride.conducteur.personne.lastname + " " + ride.conducteur.personne.firstname : "Aucun conducteur",
            driverPhone: (ride.conducteur && ride.conducteur.personne) ? ride.conducteur.personne.indicatifTel + ride.conducteur.personne.tel : "",
            waitingStart: new Date(ride.debutAttente).toLocaleDateString("fr-FR") + ' à ' + new Date(ride.debutAttente).toLocaleTimeString("fr-FR"),
            waitingDelay: elapsedTimeInMinute || 0,
            pays: ride.pays,
            tel: ride.tel && ride.tel != "" ? ride.indicatifTel + ride.tel : "",
            passengerEmail: ride.email,
            driverEmail: ride.email,
            startPlace: ride.lieuDepart ? ride.lieuDepart : "lieu inconnu",
            endPlace: ride.lieuArrive ? ride.lieuArrive : "Destination inconnu",
            distance: ride.distance,
            startTime: ride.dateHeureDepart ? new Date(ride.dateHeureDepart).toLocaleDateString("fr-FR") + ' à ' + new Date(ride.dateHeureDepart).toLocaleTimeString("fr-FR") : "Aucune date",
            endTime: ride.dateHeureArrivee ? new Date(ride.dateHeureArrivee).toLocaleDateString("fr-FR") + ' à ' + new Date(ride.dateHeureArrivee).toLocaleTimeString("fr-FR") : "Aucune date",
            rideRequestTime: ride.rideRequestTime ? new Date(ride.dateCreation).toLocaleDateString("fr-FR") + ' à ' + new Date(ride.dateCreation).toLocaleTimeString("fr-FR") : "Aucune date",
            carType: ride.moyenTransport + " " + (ride.planifier ? "Planifiée" : "Directe "),
            commission: ride.commission || 0,
            driverCost: ride.prixConducteur || 0,
            statut: ride.statut,
            penalite: ride.penaliteAnnulation || 0,
            motif: ride.motifAnnulation ? ride.motifAnnulation : "Aucun motif",
            cancelDate: ride.dateAnnulation ? new Date(ride.dateAnnulation).toLocaleString("fr-FR") : "Date non valide",
            planedDate: ride.planifier ? new Date(ride.heure_depart_choisie).toLocaleString("fr-FR"):" Course standard",
          };
        }).sort((a, b) => b.id - a.id);
        this.canceledRequestData = formattedTable;
        this.rowsCanceledRequestData = formattedTable.length;
        this.totalCanceledRows = response.data.total;
      }
    },
    handleStop(id) {
      console.log("inside", id)
      this.stopModal = true
      this.courseId = id

    },
    handleCancel(id) {
      console.log("inside", id)
      this.cancelModal = true
      this.courseId = id

    },
    handleStart(id) {
      console.log("inside", id)
      this.startModal = true
      this.courseId = id

    },
    handlePause(id) {
      console.log("inside", id)
      this.pauseModal = true
      this.courseId = id

    },
    async startRide() {
      const response = await apiRequest('PUT', 'dash/demarrer-course', { courseId: this.courseId })

      if (response && response.status == 200) {
        this.startModal = false
        this.$toast.success("Course démarrée")
        //console.log("response", response.status)
        this.init()
      } else {

        this.$toast.success("Erreur interne")
        this.startModal = false

      }

    },
    async cancelRide() {
      const response = await apiRequest('PUT', 'dash/annuler-courses', { courseId: this.courseId })

      if (response && response.status == 200) {
        this.cancelModal = false
        this.$toast.success("Course annulée")
        console.log("response", response.status)
        this.init()
      } else {

        this.$toast.success("Erreur interne")
        this.cancelModal = false

      }

    },
    async stopRide() {
      const response = await apiRequest('POST', 'dash/terminer-course', { courseId: this.courseId })
      
      if (response && response.status == 200) {
        this.stopModal = false
        this.$toast.success("Course terminée")
        console.log("response", response.status)
        this.init()
      } else {

        this.$toast.success("Erreur interne")
        this.stopModal = false

      }

    },

    async pauseRide() {
      console.log("bbbbbb");
      const response = await apiRequest('POST', 'dash/end-pause/taxi', { courseId: this.courseId })
    
      console.log("Reponse du serveur", response);

      if (response && response.status == 200) {
        this.pauseModal = false
        this.$toast.success("Course en pause")
        console.log("response", response.status)
        this.init()
      } else {

        this.$toast.success("Erreur interne")
        this.pauseModal = false

      }

    },
    async init() {


      // Listen for events


      //Chargement des données
      const data = await apiRequest("GET", "courses-encours", undefined, false);
      console.log("courses encours", data)
      if (data.data && data.data.courses) {


        const formattedTable = data.data.courses.map((ride) => {

          if (ride.debutAttente && ride.dateHeureDepart) {

            let startWaiting = new Date(ride.debutAttente)
            let endWaiting = new Date(ride.dateHeureDepart)
            let startWaitingTimesStamp = startWaiting.getTime();
            let endWaitingTimesStamp = endWaiting.getTime();
            const elapsedTime = endWaitingTimesStamp - startWaitingTimesStamp;
            var elapsedTimeInMinute = Math.ceil(elapsedTime / (1000 * 60))

          }

          return {
            id: ride.id,
            // rideEstimCost: ride.prixEstime,
            rideFinalCost: ride.prixFinal,
            driverId: ride.conducteur ? ride.conducteur.id : 0,
            passengerId: ride.passager ? ride.passager.id : 0,
            passenger: ride.passager ? ride.passager.personne.lastname + " " + ride.passager.personne.firstname : "Passager  Zémitcha ",
            passengerPhone: ride.passager ? ride.passager.personne.indicatifTel + ride.passager.personne.tel : "Pas de numéro",
            driver: ride.conducteur ? ride.conducteur.personne.lastname + " " + ride.conducteur.personne.firstname : "Aucun conducteur",
            driverPhone: (ride.conducteur && ride.conducteur.personne) ? ride.conducteur.personne.indicatifTel + ride.conducteur.personne.tel : "",
            waitingStart: new Date(ride.debutAttente).toLocaleDateString("fr-FR") + ' à ' + new Date(ride.debutAttente).toLocaleTimeString("fr-FR"),
            waitingDelay: elapsedTimeInMinute,
            pays: ride.pays,
            tel: ride.tel && ride.tel != "" ? ride.indicatifTel + ride.tel : "",
            passengerEmail: ride.email,
            driverEmail: ride.email,
            startPlace: ride.lieuDepart ? ride.lieuDepart : "lieu inconnu",
            endPlace: ride.lieuArrive ? ride.lieuArrive : "Destination inconnu",
            distance: ride.distance,
            pauses: JSON.stringify(ride.pausees),
            startTime: ride.dateHeureDepart ? new Date(ride.dateHeureDepart).toLocaleDateString("fr-FR") + ' à ' + new Date(ride.dateHeureDepart).toLocaleTimeString("fr-FR") : "Aucune date",
            endTime: ride.dateHeureArrivee ? new Date(ride.dateHeureArrivee).toLocaleDateString("fr-FR") + ' à ' + new Date(ride.dateHeureArrivee).toLocaleTimeString("fr-FR") : "Aucune date",
            rideRequestTime: ride.rideRequestTime ? new Date(ride.dateCreation).toLocaleDateString("fr-FR") + ' à ' + new Date(ride.dateCreation).toLocaleTimeString("fr-FR") : "Aucune date",
            carType: ride.moyenTransport + " " + (ride.planifier ? "Planifiée" : "Directe "),
            commission: ride.commission || 0,
            driverCost: ride.prixConducteur || 0,
            planedDate: ride.planifier ? new Date(ride.heure_depart_choisie).toLocaleString("fr-FR"):" Course standard",

            //type: type,
            statut: ride.statut,
          };
        }).sort((a, b) => b.id - a.id);
        this.processingRide = formattedTable;
        this.rowDataProcessingRide = formattedTable.length;
        this.totalTableRow = formattedTable.length;
        this.totalRideInProcess = data.data.total;
      }
    },



    async newCourseId(val) {
      this.selectedRideId = val
      console.log("handler reached");
      console.log("val", val);
      this.showMap = true
      await this.trackPosition()
      this.showMap = true
    },

    async trackPosition() {
      // Start the interval outside of the API call
      this.positionTrackingInterval = setInterval(async () => {
        try {
          const response = await apiRequest('GET', `one-courses-deplacement?courseId=${this.selectedRideId}`);

          if (response && response.status === 200) {
            var checkedIcon = '';


            if (response.data.type == "VIP") {
              checkedIcon = this.vipIcon

            } else if (response.data.type == "BASIC") {
              checkedIcon = this.basicIcon

            }
            else if (response.data.type == "CONFORT") {
              checkedIcon = this.comfortIcon

            } else if (response.data.type == "ZEM") {
              checkedIcon = this.zemIcon
            } else if (response.data.type == "TRICYCLE") {
              checkedIcon = this.tryIcon
            }else if (response.data.type == "ECO") {
              checkedIcon = this.ecoIcon
            }
            this.icon = checkedIcon;
            console.log("response.data", response.data);
            const newPosition = {
              driverId: response.data.conducteur.id,
              coords: response.data.ligne.slice(-1)[0],
              prevCoords: response.data.ligne.slice(-2, -1)[0],
            };
            this.updateMarkerPositions([newPosition]);
          } else {
            console.error('Failed to fetch driver position');
          }
        } catch (error) {
          console.error('Error fetching driver position:', error);
        }
      }, 20000); // 20 seconds interval
    },

    calculateBearing(start, end) {
      const startLat = this.toRadians(start.lat);
      const startLng = this.toRadians(start.lng);
      const endLat = this.toRadians(end.lat);
      const endLng = this.toRadians(end.lng);

      const y = Math.sin(endLng - startLng) * Math.cos(endLat);
      const x = Math.cos(startLat) * Math.sin(endLat) -
        Math.sin(startLat) * Math.cos(endLat) * Math.cos(endLng - startLng);
      const bearing = this.toDegrees(Math.atan2(y, x));
      return (bearing + 360) % 360;
    },

    toRadians(degrees) {
      return degrees * (Math.PI / 180);
    },

    toDegrees(radians) {
      return radians * (180 / Math.PI);
    },
    updateMarkerPositions(newPositions) {
      console.log("newP  newPosition ", newPositions);
      const newPosition = newPositions[0];
      const prevPosition = { ...this.markerPosition };
      this.markerPosition = newPosition.coords;
      if (!this.marker.path) this.marker.path = [];
      this.marker.path.push(newPosition.coords);
      this.animateMarker(prevPosition, newPosition.coords);
    },

    animateMarker(prevPosition, newPosition) {
      console.log("newP", newPosition);
      gsap.to(this.$refs.carIcon, {
        duration: 1,
        x: newPosition.lng,
        y: newPosition.lat,
        ease: 'power1.inOut',
        onUpdate: () => {
          this.markerPosition = { ...newPosition };
          this.center = { ...newPosition };
          this.updateCarRotation(prevPosition, newPosition);
        },
      });
    },
    updateCarRotation(prevPosition, newPosition) {
      const bearing = this.calculateBearing(prevPosition, newPosition);
      const carIconElement = document.querySelector('.car-icon');
      if (carIconElement) {
        carIconElement.style.transform = `rotate(${bearing}deg)`;
      } else {
        console.error("Car icon element not found.");
      }
    },

    async completedRideMet() { 

      const data = await apiRequest("GET", "courses-terminee", undefined, false);
      console.log("bb");
      //console.log("totalRideCompleted", data.courses);
      console.log("totalRideCompleted", data.data.courses);
      //if (data && data.data)
      if (data.data && data.data.courses && data.data.total !== undefined) {
        const formattedTable = data.data.courses.map((ride) => {
          if (ride.debutAttente && ride.dateHeureDepart) {

            let startWaiting = new Date(ride.debutAttente)
            let endWaiting = new Date(ride.dateHeureDepart)
            let startWaitingTimesStamp = startWaiting.getTime();
            let endWaitingTimesStamp = endWaiting.getTime();
            const elapsedTime = endWaitingTimesStamp - startWaitingTimesStamp;
            var elapsedTimeInMinute = Math.ceil(elapsedTime / (1000 * 60))

          }

          //const pauses = JSON.parse(ride.pauses);
          //console.log("Pauses",pauses)
          this.fullPause = 0
          this.fullAmount = 0

          if (JSON.parse(ride.pauses).length >= 1) {
            const pauses = JSON.parse(ride.pauses);
            //console.log("Pauses",pauses)
            pauses.forEach(p => {
              let startWaiting = new Date(p.start)
              let endWaiting = new Date(p.end)
              let startWaitingTimesStamp = startWaiting.getTime();
              let endWaitingTimesStamp = endWaiting.getTime();
              const elapsedTime = endWaitingTimesStamp - startWaitingTimesStamp;
              const elapsedTimeInMinute = Math.ceil(elapsedTime / (1000 * 60))

              //console.log('ela', elapsedTimeInMinute)
              this.fullPause += elapsedTimeInMinute;
              this.fullAmount += p.montant;

            })

            console.log('this.fullPause', this.fullPause)

          }




          return {
            id: ride.id,
            // rideEstimCost: ride.prixEstime,

            rideFinalCost: ride.prixFinal,
            driverId: ride.conducteur ? ride.conducteur.id : 0,
            passengerId: ride.passager ? ride.passager.id : 0,
            passenger: ride.passager ? ride.passager.personne.lastname + " " + ride.passager.personne.firstname : "Passager  Zémitcha ",
            passengerPhone: ride.passager ? ride.passager.personne.indicatifTel + ride.passager.personne.tel : "Pas de numéro",
            driver: ride.conducteur ? ride.conducteur.personne.lastname + " " + ride.conducteur.personne.firstname : "Aucun conducteur",
            driverPhone: (ride.conducteur && ride.conducteur.personne) ? ride.conducteur.personne.indicatifTel + ride.conducteur.personne.tel : "",
            waitingStart: new Date(ride.debutAttente).toLocaleDateString("fr-FR") + ' à ' + new Date(ride.debutAttente).toLocaleTimeString("fr-FR"),
            waitingDelay: elapsedTimeInMinute,
            pays: ride.pays,
            tel: ride.tel && ride.tel != "" ? ride.indicatifTel + ride.tel : "",
            passengerEmail: ride.email,
            driverEmail: ride.email,
            startPlace: ride.lieuDepart ? ride.lieuDepart : "lieu inconnu",
            endPlace: ride.lieuArrive ? ride.lieuArrive : "Destination inconnu",
            distance: ride.distance,
            pauses: JSON.stringify(ride.pausees),
            startTime: ride.dateHeureDepart ? new Date(ride.dateHeureDepart).toLocaleDateString("fr-FR") + ' à ' + new Date(ride.dateHeureDepart).toLocaleTimeString("fr-FR") : "Aucune date",
            endTime: ride.dateHeureArrivee ? new Date(ride.dateHeureArrivee).toLocaleDateString("fr-FR") + ' à ' + new Date(ride.dateHeureArrivee).toLocaleTimeString("fr-FR") : "Aucune date",
            rideRequestTime: ride.rideRequestTime ? new Date(ride.dateCreation).toLocaleDateString("fr-FR") + ' à ' + new Date(ride.dateCreation).toLocaleTimeString("fr-FR") : "Aucune date",
            carType: ride.moyenTransport + " " + (ride.planifier ? "Planifiée" : "Directe "),
            commission: ride.commission || 0,
            driverCost: ride.prixConducteur || 0,
            statut: ride.statut,
            pause: this.fullPause,
            pauseAmount: this.fullAmount,
            pauseDistance: ride.distanceIncitation,
          };
        }).sort((a, b) => b.id - a.id);
        this.completedRide = formattedTable;
        //this.totalRideCompleted = formattedTable.length;
        this.totalRideCompleted = data.data.total;
      }

    },


    showMapModal(idTrajet) {
      this.showMap = true;

      this.$nextTick(() => {
        this.getPosition(idTrajet);
      })




    },

    getPosition(idTrajet) {
      console.log("idtrahet", idTrajet)

      this.markers = [];
      this.db.collection("notifdash")
        .where("idTravel", "==", idTrajet)
        .where("typeNotif", "==", 5)
        .get()
        .then(querySnapshot => {
          const doc = querySnapshot.docs[0]

          console.log("doc.data()", querySnapshot);
          // prendre le premier doc
          // this.markers.position.lat = doc.data().lat;
          // this.markers.position.lng = doc.data().lng;
          console.log("ccords", this.lat, this.lat)
          console.log("this markrs before", this.markers);

          if (doc.data().isDriver == true) {

            this.isDriver = true

            const marker = {
              position: { lat: doc.data().lat, lng: doc.data().lng },
              label: 'Cond'
            }
            this.markers = marker;

          } else {
            this.isDriver = false
            const marker = {
              position: { lat: doc.data().lat, lng: doc.data().lng },
              label: 'Passager'

            }
            this.markers = marker;
          }


          this.center = { lat: doc.data().lat, lng: doc.data().lng };

          console.log("this markers data", this.markers);

        });
    },


    onFiltered(filteredItems) {
      this.totalTableRow = filteredItems.length;
      this.currentPage = 1;
    },
    goToDetail(row) {
      console.log("row", row)
      router.push(`/user/user_info/${row}`);
    },
    goToDetailDriver(row) {
      console.log("row", row.driverId)
      router.push(`/user/user_info/${row.driverId}`);
    },
    openNewTab(row) {
      window.open(`https://adminrmobility.raynis.co/user/user_info/${row.driverId}`, '_blank');
    },

    decrement() {
      this.userChargeLoader = true;

      this.startValue -= 500;
      this.endValue -= 500;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
      this.activeConducteur = false;
    },

    increment() {
      this.userChargeLoader = true;
      this.startValue += 500;
      this.endValue += 500;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
      this.activeConducteur = false;
    },
    incrementToLast() {
      this.userChargeLoader = true;
      this.startValue = this.totalValue - 500;
      this.endValue = this.totalValue;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
      this.activeConducteur = false;
    },
    firstVal() {
      this.userChargeLoader = true;
      this.startValue = 1;
      this.endValue = 500;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
      this.activeConducteur = false;
    },
  },
  beforeDestroy() {
    // Close the WebSocket connection when the component is destroyed
    if (this.socket) {
      this.socket.close();
      this.selectedRideId = null

    }
    clearInterval(this.fetchLivePositionsInterval);
    if (this.positionTrackingInterval) {
      clearInterval(this.positionTrackingInterval);
    }
  },

  watch: {
    activeConducteur(newValue, oldValue) {
      if (newValue === true) {
        this.userChargeLoader = true
        console.log("Old", oldValue);


        if (this.newDataUser.length != 0) {
          this.userChargeLoader = true
          this.processingRide = [];
          this.newDataUser.forEach((element) => {
            if (element.trajet >= 1) {
              this.processingRide.push(element);
            }
          });

          this.newDataUser = this.processingRide
          this.userChargeLoader = false
        }
        else {
          this.userChargeLoader = true
          this.processingRide.forEach((element) => {
            if (element.trajet >= 1) {
              this.newDataUser.push(element);
            }
          });
          this.processingRide = []
          this.userChargeLoader = false
        }



      } else {
        if (this.newDataUser.length != 0) {
          this.processingRide = [];
          this.actif(this.startValue, this.endValue);
          this.userChargeLoader = false;
        } else {
          console.log("non non non");

          this.init();
          this.userChargeLoader = false;
        }
      }
    },
  },
};

/* const dots = document.querySelector('.loaading__dots')
  const addAnimate = ()=>{
    dots.classList.add('animate')
    setTimeout(()=>{
      dots.classList.remove('aniamte');
      setTimeout(()=>{
        addAnimate()
      },100)
    }, 2600)


    }
    addAnimate(); */
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row rowBase">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <b-card header-class="bg-transparent border-primary" class="border border-primary">
                  <template v-slot:header>
                    <h5 class="my-0 mb-4">A venir</h5>
                  </template>
                  <div class="float-right">
                    <h1 class="text-primary">{{ rowsrequestData }}</h1>
                  </div>
                </b-card>
              </div>

              <div class="col-md-3">
                <b-card header-class="bg-transparent border-primary" style="height:inherit"
                  class="border border-primary">
                  <template v-slot:header>
                    <h5 class="my-0 mb-4">En cours</h5>

                  </template>
                  <div class="float-right">
                    <h1 class="text-primary">{{ totalRideInProcess }}</h1>
                  </div>
                </b-card>
              </div>
              <div class="col-md-3">
                <b-card header-class="bg-transparent border-success" class="border border-success">
                  <template v-slot:header>
                    <h5 class="my-0 mb-4">Terminées</h5>
                  </template>
                  <div class="float-right">
                    <h1 class="text-primary">{{ totalRideCompleted }}</h1>
                  </div>
                </b-card>
              </div>
              <div class="col-md-3">
                <b-card header-class="bg-transparent border-success" class="border border-success">
                  <template v-slot:header>
                    <h5 class="my-0 mb-4">Annulées</h5>
                  </template>
                  <div class="float-right">
                    <h1 class="text-primary">{{ totalCanceledRows }}</h1>
                  </div>
                </b-card>
              </div>
            </div>
          </div>
        </div>
        <div class="card homeContainer">
          <!-- <div class="containerDot" v-if="processingRide.length == 0 && newDataUser.length == 0">
            <span class="loading">Chargement en cours... </span>
           
          </div> -->
          <div class="d-flex align-items-center otherThing" v-if="userChargeLoader === true">
            <span class="loading">Chargement en cours...</span>
            <div aria-hidden="true"></div>
          </div>

          <div class="card-body pt-0">
            <div>
              <h4 class="card-title mt-4">
                Total d'utilisateurs : {{ totalTableRow }}
              </h4>
            </div>

            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Courses à venir: {{ rowsrequestData }}
                  </a>
                </template>
                <div class="row d-flex justify-content-between align-items-center mt-4 mb-3">

                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div class="inputCol">


                      <div class="input">
                        <input type="search" id="serchInput" placeholder="Entrez un mot clé..." v-model="recherche"
                          @keyup.enter="userSearch" />
                      </div>

                    </div>

                  </div>
                  <!-- End search -->

                  <div class=" mb-2">
                    <b-pagination v-model="currentPage" :total-rows="requestData.length" :per-page="perPage" :limit="1"
                      first-text="◀" last-text="▶" aria-controls="my-table">
                    </b-pagination>
                  </div>
                </div>
                <div class="table-responsive mt-2">
                  <b-table id="trajet" style="cursor: pointer" class="table-centered"
                    :items="requestData.length != 0 ? requestData : requestData" :fields="fieldsStart" responsive="sm"
                    :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter" :per-page="perPage"
                    :current-page="currentPage" :filter-included-fields="filterOn" @filtered="onFiltered"
                    @row-clicked="goToDetailDriver(row.driverId)">

                    <template v-slot:cell(username)="row">

                      <div>

                        {{ row.value }}
                        <!-- <span class="label label-warning">Taxi</span> -->
                        <!-- &nbsp;<span class="badge badge-pill badge-success m-2" style="font-size: 100%;background-color: goldenrod; color: #fff;" >Taxi</span> -->


                      </div>

                    </template>
                    <template v-slot:cell(passenger)="row">
                      <div @click="goToDetail(row.item.passengerId)">
                        {{ row.value }}
                      </div>
                    </template>
                    <template v-slot:cell(badge)="row">
                      <div class="py-2 d-flex justify-content-center" :class="{
      'fa fa-star': `${row.value}` === 'false',
      'fa fa-star badge-soft-warning':
        `${row.value}` === 'true',
    }">
                      </div>
                    </template>
                    <template v-slot:cell(statut)="row">
                      <div class="py-2 d-flex justify-content-center badge font-size-12" :class="{
      'badge-soft-danger': `${row.value}` === 'Suspendu',
      'badge-soft-success': `${row.value}` === 'Actif',
    }">
                        {{ row.value }}
                      </div>
                    </template>
                    <template v-slot:cell(action)="row">
                      <button class="btn btn-danger mb-2"  @click.prevent="handleCancel(row.item.id)">
                          Annuler
                        </button>
                    </template>




                    <template v-slot:cell(passengerPhone)="row">
                      <a :href="'https://wa.me/' + ((row.value).replace(' ', '')).replace('+', '')" class="text-success"
                        v-b-tooltip.hover title="Envoyer un message whatsapp" target="_blank">
                        {{ row.value }}
                      </a>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                   
                    <transition mode="out-in">
                      <div class="dataTables_paginate paging_simple_numbers float-right">
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <li>
                            <span class="interval"> {{ startValue }}</span> -
                            <span class="interval">{{ endValue }}</span> sur
                            {{ totalValue }}
                          </li>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <li>
                            <button class="myBouton" @click="firstVal()" :disabled="startValue == 1"
                              data-toggle="tooltip" data-placement="bottom" title="Prem">
                              <ion-icon name="play-skip-back-outline"></ion-icon>
                            </button>
                            <button type="button" class="myBouton" @click="decrement()" :disabled="endValue == 500"
                              data-toggle="tooltip" data-placement="bottom" title="Préc">
                              <ion-icon name="chevron-back-outline"></ion-icon>
                            </button>
                            <button class="myBouton" @click="increment()" data-toggle="tooltip" data-placement="bottom"
                              title="Suiv">
                              <ion-icon name="chevron-forward-outline"></ion-icon>
                            </button>
                            <button class="myBouton" @click="incrementToLast()" data-toggle="tooltip"
                              data-placement="bottom" title="Dern">
                              <ion-icon name="play-skip-forward-outline"></ion-icon>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </transition>
                  </div>
                </div>
                <!--      <div class="row">
                  <h3 class="loading">
                    Loading   
                    <div class="loaading__dots animate" id="loaading__dots">
                      <span class="loading__dot"></span>
                      <span class="loading__dot"></span>
                      <span class="loading__dot"></span>
                      <span class="loading__dot"></span>
                     <span class="loading__dot-down"></span>
                    </div>
                  </h3>
                </div> -->
              </b-tab>

              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Courses en cours: {{ totalRideInProcess }}
                  </a>
                </template>
                <div class="row d-flex justify-content-between align-items-center mt-4 mb-3">

                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div class="inputCol">


                      <div class="input">
                        <input type="search" id="serchInput" placeholder="Entrez un mot clé..." v-model="recherche"
                          @keyup.enter="userSearch" />
                      </div>

                    </div>

                  </div>
                  <!-- End search -->

                  <div class=" mb-2">
                    <b-pagination v-model="currentPageD" :total-rows="processingRide.length" :per-page="perPageD"
                      :limit="1" first-text="◀" last-text="▶" aria-controls="processing">
                    </b-pagination>
                  </div>
                </div>
                <div class="table-responsive mt-2">
                  <b-table id="processing" style="cursor: pointer" class="table-centered"
                    :items="processingRide.length != 0 ? processingRide : processingRide" :fields="fields"
                    responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                    :current-page="currentPageD" :per-page="perPageD" :filter-included-fields="filterOn"
                    @filtered="onFiltered" @row-clicked="goToDetailDriver">


                    <template v-slot:cell(action)="row">
                      <div class="text-center text-nowrap" >  
                      </div>
                      <!-- <div class="text-center ">
                        <button class="btn btn-success mb-2" v-if="row.item.statut == 'PROXIMITE' || row.item.statut == 'ENCOURS'"  @click.prevent="handleStop(row.item.id)">
                          Terminer
                        </button>
                        <button class="btn btn-danger mb-2"  @click.prevent="handleCancel(row.item.id)">
                          Annuler
                        </button>
                        <button v-if="row.item.statut == 'ACCEPTEE'" class="btn btn-info"  @click.prevent="handleStart(row.item.id)">
                          Démarrer
                        </button>

                      </div> -->
                      <div class="text-center">
                        <!-- Boutons visibles seulement si le statut n'est pas "pause" -->
                        <template v-if="row.item.statut !== 'PAUSE'">
                          <button 
                            class="btn btn-success mb-2" 
                            v-if="row.item.statut === 'PROXIMITE' || row.item.statut === 'ENCOURS'" 
                            @click.prevent="handleStop(row.item.id)">
                            Terminer
                          </button>
                          <button 
                            class="btn btn-danger mb-2"  
                            @click.prevent="handleCancel(row.item.id)">
                            Annuler
                          </button>
                          <button 
                            v-if="row.item.statut === 'ACCEPTEE'" 
                            class="btn btn-info"  
                            @click.prevent="handleStart(row.item.id)">
                            Démarrer
                          </button>
                        </template>
                        
                        <!-- Bouton visible uniquement si le statut est "pause" -->
                        <button 
                          v-if="row.item.statut === 'PAUSE'" 
                          class="btn btn-warning" 
                          @click.prevent="handlePause(row.item.id)">
                          Quitter la pause
                        </button>
                      </div>

                    </template>
                    <template v-slot:cell(passenger)="row">
                      <div @click="goToDetail(row.item.passengerId)">
                        {{ row.value }}
                      </div>
                    </template>

                    <template v-slot:cell(suivi)="row">

                      <div @click="newCourseId(row.item.id)" class="justify-content-center text-center">

                        <i class="fa fa-eye text-success"> </i>

                      </div>

                    </template>
                    <template v-slot:cell(badge)="row">
                      <div class="py-2 d-flex justify-content-center" :class="{
      'fa fa-star': `${row.value}` === 'false',
      'fa fa-star badge-soft-warning':
        `${row.value}` === 'true',
    }"></div>
                    </template>
                    <template v-slot:cell(statut)="row">
                      <div class="py-2 d-flex justify-content-center badge font-size-12" :class="{
      'badge-soft-danger': `${row.value}` === 'TERMINEE',
      'badge-soft-success': `${row.value}` === 'ATTENTE',
      'badge-soft-warning': `${row.value}` === 'ENCOURS',
    }">
                        {{ row.value }}
                      </div>
                    </template>


                    <template v-slot:cell(driverPhone)="row">
                      <a :href="'https://wa.me/' + row.value" class="text-success" v-b-tooltip.hover
                        title="Envoyer un message whatsapp" target="_blank">
                        {{ row.value }}
                      </a>
                    </template>
                    <template v-slot:cell(passengerPhone)="row">
                      <a :href="'https://wa.me/' + row.value" class="text-success" v-b-tooltip.hover
                        title="Envoyer un message whatsapp" target="_blank">
                        {{ row.value }}
                      </a>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <!--   <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                    
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                          :aria-controls="trajet"
                        ></b-pagination>
                      </ul>
                    </div> -->
                    <transition mode="out-in">
                      <div class="dataTables_paginate paging_simple_numbers float-right">
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <li>
                            <span class="interval"> {{ startValue }}</span> -
                            <span class="interval">{{ endValue }}</span> sur
                            {{ totalValue }}
                          </li>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <li>
                            <button class="myBouton" @click="firstVal()" :disabled="startValue == 1"
                              data-toggle="tooltip" data-placement="bottom" title="Prem">
                              <ion-icon name="play-skip-back-outline"></ion-icon>
                            </button>
                            <button type="button" class="myBouton" @click="decrement()" :disabled="endValue == 500"
                              data-toggle="tooltip" data-placement="bottom" title="Préc">
                              <ion-icon name="chevron-back-outline"></ion-icon>
                            </button>
                            <button class="myBouton" @click="increment()" data-toggle="tooltip" data-placement="bottom"
                              title="Suiv">
                              <ion-icon name="chevron-forward-outline"></ion-icon>
                            </button>
                            <button class="myBouton" @click="incrementToLast()" data-toggle="tooltip"
                              data-placement="bottom" title="Dern">
                              <ion-icon name="play-skip-forward-outline"></ion-icon>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </transition>
                  </div>
                </div>

              </b-tab>
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active"> Courses Terminées : {{ totalRideCompleted }}</a>
                </template>
                <div class="row d-flex justify-content-between align-items-center mt-4 mb-3">

                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div class="inputCol">


                      <div class="input">
                        <input type="search" id="serchInput" placeholder="Entrez un mot clé..." v-model="recherche"
                          @keyup.enter="userSearch" />
                      </div>

                    </div>

                  </div>
                  <!-- End search -->

                  <div class=" mb-2">
                    <b-pagination v-model="currentPageF" :total-rows="completedRide.length" :per-page="perPageF"
                      :limit="1" first-text="◀" last-text="▶" aria-controls="processing">
                    </b-pagination>
                  </div>
                </div>
                <div class="table-responsive">
                  <b-table id="trajetD" style="cursor: pointer" class="table-centered" :items="completedRide"
                    :fields="fieldsFinished" responsive="sm" :per-page="perPageF" :current-page="currentPageF"
                    :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                    :filter-included-fields="filterOn" @filtered="onFiltered" @row-clicked="goToDetailDriver">
                    <template v-slot:cell(badge)="row">
                      <div class="d-flex justify-content-center" :class="{
      'fa fa-star': `${row.value}` === 'false',
      'fa fa-star badge-soft-warning':
        `${row.value}` === 'true',
    }"></div>
                    </template>
                    <template v-slot:cell(statut)="row">
                      <div class="badge font-size-12" :class="{
      'badge-soft-info': `${row.value}` === 'TERMINEE',
      'badge-soft-danger': `${row.value}` === 'SUSPENDU',
    }">
                        {{ row.value }}
                      </div>
                    </template>
                    <template v-slot:cell(driverPhone)="row">
                      <a :href="'https://wa.me/' + row.value" class="text-success" v-b-tooltip.hover
                        title="Envoyer un message whatsapp" target="_blank">
                        {{ row.value }}
                      </a>
                    </template>
                    <template v-slot:cell(passenger)="row">
                      <div @click="goToDetail(row.item.passengerId)">
                        {{ row.value }}
                      </div>
                    </template>
                    <template v-slot:cell(passengerPhone)="row">
                      <a :href="'https://wa.me/' + row.value" class="text-success" v-b-tooltip.hover
                        title="Envoyer un message whatsapp" target="_blank">
                        {{ row.value }}
                      </a>
                    </template>
                    <template v-slot:cell(action)="row">
                      <a href="javascript:void(0);" class="text-success" v-b-tooltip.hover
                        @click="activeUserPop(row.item)" title="Activer">
                        <i class="mdi mdi-check font-size-18"></i>
                      </a>
                      <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover @click="deleteUser(row.item)"
                        title="Supprimer" v-if="row.item.trajet == 0">
                        <i class="fas fa-trash"></i>
                      </a>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="currentPageD" :total-rows="rows" :per-page="perPageD"
                          :rows="rowsinactif"></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active"> Courses annulées : {{ totalCanceledRows }}</a>
                </template>
                <div class="row d-flex justify-content-between align-items-center mt-4 mb-3">

                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div class="inputCol">


                      <div class="input">
                        <input type="search" id="serchInput" placeholder="Entrez un mot clé..." v-model="recherche"
                          @keyup.enter="userSearch" />
                      </div>

                    </div>

                  </div>
                  <!-- End search -->

                  <div class=" mb-2">
                    <b-pagination v-model="currentPageC" :total-rows="canceledRequestData.length" :per-page="perPageC"
                      :limit="1" first-text="◀" last-text="▶" aria-controls="processing">
                    </b-pagination>
                  </div>
                </div>
                <div class="table-responsive">
                  <b-table id="trajetD" style="cursor: pointer" class="table-centered" :items="canceledRequestData"
                    :fields="fieldsC" responsive="sm" :per-page="perPageC" :current-page="currentPageC"
                    :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                    :filter-included-fields="filterOn" @filtered="onFiltered" @row-clicked="goToDetailDriver">
                    <template v-slot:cell(badge)="row">
                      <div class="d-flex justify-content-center" :class="{
      'fa fa-star': `${row.value}` === 'false',
      'fa fa-star badge-soft-warning':
        `${row.value}` === 'true',
    }"></div>
                    </template>
                    <template v-slot:cell(statut)="row">
                      <div class="badge font-size-12" :class="{
      'badge-soft-danger': `${row.value}` === 'ANNULEE',

    }">
                        {{ row.value }}
                      </div>
                    </template>
                    <template v-slot:cell(passengerPhone)="row">
                      <a :href="'https://wa.me/' + row.value" class="text-success" v-b-tooltip.hover
                        title="Envoyer un message whatsapp" target="_blank">
                        {{ row.value }}
                      </a>
                    </template>
                    <template v-slot:cell(driverPhone)="row">
                      <a :href="'https://wa.me/' + row.value" class="text-success" v-b-tooltip.hover
                        title="Envoyer un message whatsapp" target="_blank">
                        {{ row.value }}
                      </a>
                    </template>
                    <template v-slot:cell(passenger)="row">
                      <div @click="goToDetail(row.item.passengerId)">
                        {{ row.value }}
                      </div>
                    </template>
                    <template v-slot:cell(action)="row">
                      <a href="javascript:void(0);" class="text-success" v-b-tooltip.hover
                        @click="activeUserPop(row.item)" title="Activer">
                        <i class="mdi mdi-check font-size-18"></i>
                      </a>
                      <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover @click="deleteUser(row.item)"
                        title="Supprimer" v-if="row.item.trajet == 0">
                        <i class="fas fa-trash"></i>
                      </a>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="currentPageD" :total-rows="rows" :per-page="perPageD"
                          :rows="rowsinactif"></b-pagination>
                      </ul>
                    </div>
                    
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>

        <div class="my-3">

          <h4 class="ml-3"> <ion-icon name="analytics" style="color: red;"></ion-icon> Suivi des Assos</h4>

          <div class="row row-cols-4 justify-content-md-end p-4 position">
            <div class=" card col-3 mx-3 p-3 bg-white rounded text-right text-success">
              <div class="indicator bg-warning">

              </div>
              <div class="card-title">
                <ion-icon name="analytics" style="color: red;"></ion-icon>
                Course en cours

              </div>

              <div class="card-data">
                0
              </div>

            </div>
            <div class=" card col-3 p-3 bg-white rounded text-right text-success">
              <div class="indicator bg-success">

              </div>
              <div class="card-title fs-3">
                Chauffeur en ligne

              </div>

              <div class="card-data">
                {{ markersLive.length }}
              </div>

            </div>

          </div>

        </div>

        <div class="card">
          <div class="card-body">
            <GmapMap :center="center" :zoom="zoom" map-type-id="terrain" style="width: 100%; height:800px">
              <GmapMarker v-for="marker in markersEncours" :key="marker.driverId" :position="marker.position"
                :clickable="true" :draggable="true">
                <transition name="car-transition">
                  <img ref="carIcon" :src="carIcon" :class="'car-icon-' + marker.driverId" alt="car-icon"
                    style="width: 40px; height: 40px;transform-origin: center;">
                </transition>

                <GmapInfoWindow :closeclick="true" @closeclick="openMarker(null)"
                  :opened="openedMarkerID === marker.driverId" @click="openMarker(m)">
                  <div class="card" style="width: 18rem">
                    <div class="card-body">
                      <h5 class="card-title">
                        <div>
                          <div class="" style="font-size: medium !important;">
                            Nom: <span>{{ marker.name }}</span>
                          </div>
                        </div>
                      </h5>


                      <div class="mb-3">
                        
                      </div>

                      <router-link :to="{ name: 'infoUserPage', params: { idUser: marker.userId } }">
                        <a style="border-radius: 20px" href="#" class="btn btn-info w-80">
                          <i class="fa fa-eye fa-md text-center" aria-hidden="true"></i>&nbsp;
                          Profil</a>
                      </router-link>
                    </div>
                  </div>

                </GmapInfoWindow>
              </GmapMarker>

              <GmapMarker v-for="m in markersLive" :key="m.driverId" :position="m.position" :clickable="true"
                :draggable="true" :icon="m.icon" @click="openMarker(m.driverId)">
                <!-- <transition name="car-transition">
                      <img ref="carIcon" :src="carIcon" class="car-icon" alt="car-icon"
                        style="width: 40px; height: 40px;transform-origin: center;">:opened="openedMarkerID === m.id"
                    </transition> -->
                <GmapInfoWindow :closeclick="true" @closeclick="openMarker(null)"
                  :opened="openedMarkerID === m.driverId" @click="openMarker(m)">
                  <div class="card" style="width: 18rem">
                    <div class="card-body">
                      <h5 class="card-title">
                        <div>
                          <div class="" style="font-size: medium !important;">
                            <span>{{ m.name }} (en ligne)</span>
                          </div>
                        </div>
                      </h5>

                      <div class="mb-3">
                        <div class="mb-3">
                          Lieu d'activité:
                          <span>{{ m.city }}</span>
                        </div>
                        <div class="mb-3">
                          Téléphone: <a :href="'hhtps://wa.me/' + (m.telephone).replace(/\s+/g, '')"
                            target="_blank">{{ m.telephone }}</a>
                        </div>

                      </div>

                      <router-link :to="{ name: 'infoUserPage', params: { idUser: m.userId } }">
                        <a style="border-radius: 20px" href="#" class="btn btn-info w-80">
                          <i class="fa fa-eye fa-md text-center" aria-hidden="true"></i>&nbsp;
                          Profil</a>
                      </router-link>
                    </div>
                  </div>

                </GmapInfoWindow>
              </GmapMarker>
              <GmapPolyline v-for="marker in markers" :key="`path-${marker.driverId}`" :path="marker.path"
                :options="{ strokeColor: 'blue', strokeWeight: 3 }" />
            </GmapMap>
          </div>
        </div>


      </div>
    </div>

    <b-modal id="modal-center" size="xl" style="width: 100%" centered v-model="showMap"
      title="Position de la confirmation de Fin trajet" title-class="font-18" hide-footer modal-full>
      <div id="map" style="height:100%;">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <GmapMap :center="center" :zoom="zoom" map-type-id="terrain" style="width: 100%; height: 500px">
                <GmapMarker :position="markerPosition" :icon="icon">
                  <transition name="car-transition">
                    <img ref="carIcon" :src="icon" class="car-icon" alt="car-icon"
                      style="width: 40px; height: 40px ;transform-origin: center;">
                  </transition>

                </GmapMarker>
                <GmapPolyline :path="marker.path" :options="{ strokeColor: 'blue', strokeWeight: 3 }" />
              </GmapMap>
            </div>
          </div>
        </div>
        <div class="row float-right mt-3">
          <b-button variant="danger" @click="showMap = false">Fermer</b-button>
        </div>
      </div>
    </b-modal>

    <b-modal id="modal-backdrop" centered v-model="stopModal" title="Voulez-vous vraiment marquer la fin de la course ?"
      title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="stopModal = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="stopRide()">Oui</b-button>
      </div>
    </b-modal>
    <b-modal id="modal-backdrop" centered v-model="startModal" title="Voulez-vous vraiment démarrer la course ?"
      title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="startModal = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="startRide()">Oui</b-button>
      </div>
    </b-modal>
    <b-modal id="modal-backdrop" centered v-model="cancelModal" title="Voulez-vous vraiment annuler cette course ?"
      title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="cancelModal = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="cancelRide()">Oui</b-button>
      </div>
    </b-modal>

    <b-modal id="modal-backdrop" centered v-model="pauseModal" title="Voulez-vous vraiment marquer la pause de la course ?"
      title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="pauseModal = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="pauseRide()">Oui</b-button>
      </div>
    </b-modal>

  </Layout>
</template>

<style>
.car-transition-enter-active,
.car-transition-leave-active {
  transition: transform 2s ease;
  /* Adjust duration as needed */
}

.car-transition-enter,
.car-transition-leave-to

/* .car-transition-leave-active in <2.1.8 */
  {
  transform: translateX(100%);
}

thead {
  background: #3AAA35;
}

thead div {
  color: #fff;
}

thead th:first-child {
  font-weight: 600 !important;
}

thead th span {
  color: #fff;
}

.nav-tabs .nav-link {
  background: #dc143c;
  transition: all .5s;
}

.nav-tabs .nav-link a {
  color: #fff;
}

.text-red {
  color: #dc143c;
}

.nav-tabs .nav-link a {
  color: #fff !important;
}

.text-green {
  color: #3AAA35;
}

.nav-tabs .nav-link a {
  color: rgba(44, 56, 74, 0.95);
}

.nav-tabs-custom .nav-item .nav-link.active a {
  color: #fff !important;
  font-weight: 600 !important;
  vertical-align: middle;
}

.nav-tabs-custom .nav-item .nav-link.active {
  font-weight: 600;
  background: #3AAA35 !important;
  border-bottom: 1px solid #000000 !important;
}

.nav-tabs-custom .nav-item .nav-link::after {
  border-bottom: 1px solid #000000 !important;
}

/*table filter design*/

.input {

  width: 300px;
  height: 35px;
  position: relative;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}

.input input:focus {
  border: 3px solid #3aaa35;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}

span.superuser {
  display: inline-flex;
  width: 20px;
  height: 20px;
  background-color: #fddb91;
  vertical-align: middle;
  border: none;
  box-shadow: -1px -1px 1px rgba(255, 255, 255, 0.95);
}

.dataTables_filter {
  padding-top: 0;
  margin-top: -10px;
}

.inputCol {
  position: relative;
  float: right;
}

.paging_simple_numbers {
  background: #3AAA35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;

  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
}

.paging_simple_numbers ul li {
  color: white !important;
}


.paging_simple_numbers ul li button {
  color: #fff !important;
}

/* .pagination ul {
  position: relative;
  display: flex;
  padding: 10px 20px;
  align-items: center;
} */

.b-pagination {
  background-color: #3AAA35 !important;
  color: #fff !important;
}

.pagination .page-item {
  background-color: #3AAA35 !important;
}

.myIcon {
  margin-top: 2px;
}

.loading__dots {
  display: inline-flex;
  column-gap: 0.25rem;
}

.loading__dot {
  position: relative;
  width: 8px;
  height: 8px;
  background-color: hsl(0, 0%, 12%);
  border-radius: 50%;
}

.loading__dot:nth-child(1) {
  position: absolute;
  transform: scale(0);
}

.loading__dot:nth-child(4) {
  background: transparent;
}

.loading__dot-down {
  display: block;
  width: 8px;
  height: 8px;
  background-color: hsl(0, 0%, 12%);
  border-radius: 50%;
  transform: translate(0);
}

.animate .loading__dot:nth-child(1) {
  animation: scale-dot 0.8s 0.2s forwards;
}

.animate .loading__dot:nth-child(2),
.animate .loading__dot:nth-child(3) {
  animation: move-right 0.6s forwards;
}

.animate .loading__dot-down {
  animation: move-right-down 0.8s 0.2s forwards linear,
    move-down 2.8s 0.3s forwards ease-in;
}

@keyframes scale-dot {
  100% {
    transform: scale(1);
  }
}

@keyframes move-right {
  100% {
    transform: translateX (0.75rem);
  }
}

@keyframes move-right-down {
  50% {
    transform: translate(1.5rem, 0.25rem);
  }

  100% {
    transform: translate(2rem, 0.5rem);
  }
}

@keyframes move-down {
  100% {
    transform: translate(10rem, 80vh);
  }
}

.loading {
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
}

.loading {
  font-weight: bold;
  font-size: 1.001rem;
  width: 100%;
  white-space: nowrap;
  color: hsl(0, 0%, 12%);
  text-align: center;
  display: inline-block;
  margin: 0 auto !important;
}

.otherThing {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -55px;
}

.homeContainer {
  position: relative;

}

.containerDot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
  width: 250px;
  height: 40px;
  position: absolute;
  top: 2%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.dots {
  position: relative;
  width: 100px;
  height: 50px;
  transform: scale(0.25, 0.25);
  margin-left: -10px;
}

.dot {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translate(0, -40%);
  opacity: 0;
  border-radius: 50%;
  background-color: hsl(0, 0%, 12%);
}

.dot-1 {
  animation: animation-dots 1.5s infinite linear 1s;
}

.dot-2 {
  animation: animation-dots 1.5s infinite linear 0.5s;
}

.dot-3 {
  animation: animation-dots 1.5s infinite linear;
}

@keyframes animation-dots {
  0% {
    left: -100px;
    opacity: 0;
  }

  20% {
    left: 0px;
    opacity: 1;
  }

  80% {
    left: 80px;
    opacity: 1;
  }

  100% {
    opacity: 0;
    left: 150px;
  }
}

.rowBase {
  position: relative;
}

ion-icon {
  cursor: pointer;
}

.myBouton {
  background: transparent;
  text-align: center;
  outline: none;
  border: none;
}

/* .interval{
padding: 4px;
  height: 40px;
  min-width: 100px;
  width: 80px;
  text-align: center;
  color: #262626;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: inset 0 5px 10px rgba(0, 0, 0, .1), 0 2px 5px rgba(0, 0, 0, .5);
  margin: 5px;

} */

.containerDot {
  z-index: 100;
}

.pac-container {
  z-index: 10000 !important;
}

/** Asso option */

.center input[type="checkbox"] {
  position: relative;
  width: 45px;
  min-width: 45px;
  height: 20px;
  -webkit-appearance: none;
  background: #c6c6c6;
  outline: none;
  border-radius: 20px;
  transition: -5px;
  cursor: pointer;
}

.center input:checked[type="checkbox"] {
  background: linear-gradient(0deg, #00d22d, #717171);
}

.center input[type="checkbox"]:before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: 0;
  left: 0;
  background: #fff;
  transform: scale(1, 1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
  color: #d6d2f8;
}

.center input:checked[type="checkbox"]:before {
  left: 25px;
}

.center {
  display: flex;
  align-items: center;
}

.mode {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: #495057;
}


.multiselect__tags {
  min-height: 50px !important;
  height: 50px;
}
</style>
