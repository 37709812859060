<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";
import db from "@/firebase/init.js";
import router from "@/router/index.js";

require("firebase/firestore");

/**
 * Advanced table component
 */
export default {
  page: {
    title: "Trajets Effectués",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      tableData: [],
      tableTrajet: "tableTrajet",
      title: "Trajets Effectués",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Trajets Effectués",
          active: true,
        },
      ],
      idtrajet: 0,
      totalRows: 1,
      currentPage: 1,

      pageOptions: [10, 100, 200],
      filter: null,
      filterOn: [],
      sortBy: "dateHeureDepart",
      sortDesc: false,
      fields: [
        { key: "itinéraire", sortable: true },
        { key: "date", sortable: true, label: "Date heure départ" },
        { key: "conducteur", sortable: true },
        { key: "numeroConducteur", sortable: true, label: "Numéro" },
        { key: "passagers", sortable: true },
        { key: "siege", label: "Référence de Siège" },
        { key: "Depart", sortable: true, label: "Date heure arrivée" },
        { key: "prix", sortable: true },
        { key: "vues", sortable: true },
        { key: "position", sortable: true, label: "Lieu de confirmation" },
      ],
      isDriver: false,

      startValue: 1,
      endValue: 500,
      totalValue: "0",
      userChargeLoader: false,
      newDatTrajetPasse: [],
      newStart: false,
      recherche: "",
      newDatTrajetPublier: [],
      parBon: 500,
      libelLoader: "Chargement en cours...",
      listePassagers: [],
      showMap: false,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      map: null,
      markerLatLng: { lat: 6.3633, lng: 2.4300 },
      center: { lat: 6.3633, lng: 2.4300 },
      zoom: 18,
      markers: [{
        positon: { lat: 6.3633, lng: 2.4300 }, label: "Conducteur"
      }
      ],

      lat: 6.3633,
      lng: 2.4300,
      db: db,
    };
  },

  async mounted() {

    //Chargement des données
    const data = await apiRequest(
      "GET",
      "admin/trajets/passes",
      undefined,
      false
    );
    console.log("data", data)
    if (data && data.data) {
      const formattedTables = data.data.trajet.map((trajet) => {
        var siegeData = [];
        trajet.passagers.forEach(passager => {

          passager.numSieges.map((name) => {
            if (name !== null && name !== undefined) {

              console.log("name", name)

              siegeData.push(name)

            } else {
              siegeData.push("non défini")
            }
          })
        })
        this.listePassagers = []
        this.totalValue = data.data.total
        trajet.passagers.forEach((element) => {
          if (element.statut == "ACTIF") {
            this.listePassagers.push(`${element.firstname} ${element.lastname}-${element.telephone}`);
          }
        });
        return {
          id: trajet.id,
          siege: siegeData,
          itinéraire:
            trajet.lieuDepart.nom ? trajet.lieuDepart.nom : "" +
              " ↦  " +
              trajet.lieuArrivee.nom ? trajet.lieuArrivee.nom : "",
          conducteurId: trajet.conducteur.id,
          conducteur: trajet.conducteur != null && trajet.conducteur.personne ?
            trajet.conducteur.personne.lastname[0] +
            ". " +
            trajet.conducteur.personne.firstname : "",
          numeroConducteur: trajet.conducteur != null && trajet.conducteur.personne ? trajet.conducteur.personne.indicatifTel +
            "" +
            trajet.conducteur.personne.tel : null,
          Depart: new Date(trajet.dateHeureArrive).toLocaleString("fr-FR"),

          prix: trajet.prix + " XOF",
          date: new Date(trajet.dateHeureDepart).toLocaleString("fr-FR"),
          passagers: this.listePassagers,

          vues: trajet.vues ? trajet.vues : 0,
        };
      });

      this.tableData = []
      formattedTables.forEach(element => {
        this.tableData.push(element)
      });
    }
    // Set the initial number of items
    this.totalRows = this.tableData.length;
    console.log(this.tableData);
  },
  methods: {
    /**
     * Search the table data with search input
     */

    goToUserDetail(id) {
      window.open(`/user/user_info/${id}`, '_blank', 'noreferrer');
    },
    onFiltered(filteredItems) {
      console.log(filteredItems);
      this.currentPage = 1;
    },
    goToDetail(row) {

      router.push(`/trajets/details_effectues/${row.id}`);
    },

    async actif(page, limit) {
      //Chargement des données

      const data = await apiRequest(
        "GET",
        "admin/trajets/passes?page=" + page + "&limit=" + limit,
        undefined,
        false
      );
      if (data && data.data) {
        const formattedTable = data.data.map((trajet) => {
          var passagers = "";
          var siegeData = [];
          trajet.passagers.forEach(passager => {

            passager.numSieges.map((name) => {
              if (name !== null && name !== undefined) {

                console.log("name", name)

                siegeData.push(name)

              } else {
                siegeData.push("non défini")
              }
            })
          })
          this.totalValue = data.data.total
          trajet.passagers.forEach((element) => {
            if (element.statut == "ACTIF") {
              passagers += "-" + element.firstname + " " + element.lastname + " - " + element.telephone + "\n";

            }
          });
          return {
            id: trajet.id,
            siege: siegeData,
            itinéraire:
              trajet.lieuDepart.nom.split(",")[0] +
              " ↦  " +
              trajet.lieuArrivee.nom.split(",")[0],
            conducteurId: trajet.conducteur.id,
            conducteur: trajet.conducteur != null ?
              trajet.conducteur.personne.lastname[0] +
              ". " +
              trajet.conducteur.personne.firstname : "",
            Depart: new Date(trajet.dateHeureDepart).toLocaleString("fr-FR"),
            nombrePlace: trajet.nombrePlace,
            prix: trajet.prix + " XOF",
            date: new Date(trajet.dateCreation).toLocaleString("fr-FR"),
            passagers: passagers,
            vues: trajet.vues ? trajet.vues : 0,
          };
        });
        this.newDatTrajetPublier = formattedTable;
        this.rowsdataUser = formattedTable.length;
        this.totalRows = formattedTable.length;
        this.userChargeLoader = false;
      }
    },

    async userSearch() {
      console.log(this.recherche);
      this.userChargeLoader = true;
      const dataSearch = await apiRequest(
        "GET",
        "admin/trajets/passes-recherche?mots=" + this.recherche,

        undefined,
        false
      );

      if (dataSearch && dataSearch.data) {
        const formattedTables = dataSearch.data.map((trajet) => {

          var siegeData = [];
          trajet.passagers.forEach(passager => {

            passager.numSieges.map((name) => {
              if (name !== null && name !== undefined) {

                console.log("name", name)

                siegeData.push(name)

              } else {
                siegeData.push("non défini")
              }

            })
          })

          this.listePassagers = []
          this.totalValue = dataSearch.data.total
          trajet.passagers.forEach((element) => {
            if (element.statut == "ACTIF") {
              this.listePassagers.push(`${element.firstname} ${element.lastname}-${element.telephone}`);
            }

          });
          return {
            id: trajet.id,
            siege: siegeData,
            itinéraire:
              trajet.lieuDepart.nom.split(",")[0] +
              " ↦  " +
              trajet.lieuArrivee.nom.split(",")[0],
            conducteurId: trajet.conducteur.id,
            conducteur: trajet.conducteur != null && trajet.conducteur.personne ?
              trajet.conducteur.personne.lastname[0] +
              ". " +
              trajet.conducteur.personne.firstname : "",
            numeroConducteur: trajet.conducteur != null && trajet.conducteur.personne ? trajet.conducteur.personne.indicatifTel +
              "" +
              trajet.conducteur.personne.tel : null,
            Depart: new Date(trajet.dateHeureArrive).toLocaleString("fr-FR"),

            prix: trajet.prix + " XOF",
            date: new Date(trajet.dateHeureDepart).toLocaleString("fr-FR"),
            passagers: this.listePassagers,

            vues: trajet.vues ? trajet.vues : 0,
          };
        });

        this.newDatTrajetPublier = formattedTables;
        this.totalRows = formattedTables.length;
      }
    },
    showMapModal(idTrajet) {
      this.showMap = true;

      this.$nextTick(() => {
        this.getPosition(idTrajet);
      })
    },

    getPosition(idTrajet) {
      console.log("idtrahet", idTrajet)

      this.markers = [];
      this.db.collection("notifdash")
        .where("idTravel", "==", idTrajet)
        .where("typeNotif", "==", 5)
        .get()
        .then(querySnapshot => {
          const doc = querySnapshot.docs[0]

          console.log("doc.data()", querySnapshot);
         
          console.log("ccords", this.lat, this.lat)
          console.log("this markrs before", this.markers);

          if (doc.data().isDriver == true) {

            this.isDriver = true

            const marker = {
              position: { lat: doc.data().lat, lng: doc.data().lng },
              label: 'Cond'
            }
            this.markers = marker;

          } else {
            this.isDriver = false
            const marker = {
              position: { lat: doc.data().lat, lng: doc.data().lng },
              label: 'Passager'

            }
            this.markers = marker;
          }
          this.center = { lat: doc.data().lat, lng: doc.data().lng };

          console.log("this markers data", this.markers)
        });
    },

    decrement() {
      this.userChargeLoader = true;

      this.startValue -= this.parBon;
      this.endValue -= this.parBon;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },

    increment() {
      this.userChargeLoader = true;
      this.startValue += this.parBon;
      this.endValue += this.parBon;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },
    incrementToLast() {
      this.userChargeLoader = true;
      this.startValue = this.totalValue - this.parBon;
      this.endValue = this.totalValue;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },
    firstVal() {
      this.userChargeLoader = true;
      this.startValue = 1;
      this.endValue = this.parBon;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    }
  },
  computed: {
    rows() {
      return this.totalValue
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="containerDot" v-if="tableData.length == 0">
            <span class="loading">Chargement en cours... </span>
          </div>
          <div class="d-flex align-items-center otherThing" v-if="userChargeLoader === true">
            <span class="loading">Chargement en cours...</span>
            <div aria-hidden="true"></div>
          </div>
          <div class="card-body">

            <div class="d-flex align-items-center otherThing" v-if="userChargeLoader === true">
              <span class="loading">{{ libelLoader }} </span>
              <div aria-hidden="true"></div>
            </div>
            <div class="row d-flex justify-content-between align-items-center p-2">
              <div>
                <h4 class="card-title mt-4">
                  Total de trajets effectués : {{ totalRows }}
                </h4>
              </div>
              <transition mode="out-in">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <li>
                      <span class="interval"> {{ startValue }}</span> -
                      <span class="interval">{{ endValue }}</span> sur
                      {{ totalValue }}
                    </li>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <li>
                      <button class="myBouton" @click="firstVal()" :disabled="startValue == 1" data-toggle="tooltip"
                        data-placement="bottom" title="Prem">
                        <ion-icon name="play-skip-back-outline"></ion-icon>
                      </button>
                      <button type="button" class="myBouton" @click="decrement()" :disabled="endValue == 500"
                        data-toggle="tooltip" data-placement="bottom" title="Préc">
                        <ion-icon name="chevron-back-outline"></ion-icon>
                      </button>
                      <button class="myBouton" @click="increment()" data-toggle="tooltip" data-placement="bottom"
                        title="Suiv" :disabled="endValue >= totalValue">
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                      </button>
                      <button class="myBouton" @click="incrementToLast()" data-toggle="tooltip" data-placement="bottom"
                        title="Dern" :disabled="endValue >= totalValue">
                        <ion-icon name="play-skip-forward-outline"></ion-icon>
                      </button>
                    </li>
                  </ul>
                </div>
              </transition>
            </div>

            <div class="row  d-flex justify-content-between mt-4">
              <div class="p-3">

              </div>
              <!-- Search -->
              <div class="inputCol">
                <div class="input ">
                  <input type="search" id="serchInput" placeholder="Rechercher..." v-model="recherche"
                    @keyup.enter="userSearch" />
                </div>

              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0 mt-2">
              <b-table id="trajet" :items="newDatTrajetPublier.length != 0 ? newDatTrajetPublier : tableData"
                :fields="fields" responsive="sm" :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered"
                @row-clicked="goToDetail">

                <template v-slot:cell(position)="row">

                  <div>
                    <button class="btn btn-success" @click="showMapModal(row.item.id)">
                      Voir
                    </button> <i class="fa fas-placeholder"></i>

                  </div>
                </template>

                <template v-slot:cell(conducteur)="row">

                  <div @click="goToUserDetail(row.item.conducteurId)">
                    <span>
                      {{ row.item.conducteur }}
                    </span>
                    <i class="fas fa-eye"></i>

                  </div>

                </template>

                <template v-slot:cell(numeroConducteur)="row" style="text-align: center">
                  <a :href="'https://wa.me/' + row.value" class="text-success" v-b-tooltip.hover
                    title="Envoyer un message whatsapp" target="_blank">
                    {{ row.value }}
                  </a>
                </template>
                <template v-slot:cell(siege)="row">

                  <div v-if="row.item.siege != null">

                    <div v-for="(name, index) in row.value" :key="index">
                      <span style="text-wrap: nowrap">
                        {{ name }}
                      </span>

                    </div>

                  </div>
                  <div v-if="row.item.siege.length === 0">

                    <span> non défini</span>

                  </div>
                </template>

                <template v-slot:cell(passagers)="row" style="text-align: center">

                  <div v-for="(ele, index) in row.value " :key="index">
                    {{ ele.split('-')[0] }}

                    <a :href="'https://wa.me/' + ele.split('-')[1]" class="text-success" v-b-tooltip.hover
                      title="Envoyer un message whatsapp" target="_blank">
                      {{ ele.split('-')[1] }}
                    </a>
                  </div>

                </template>

              </b-table>

            </div>
            <div class="row">
              <div class="col">

                <transition mode="out-in">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <li>
                        <span class="interval"> {{ startValue }}</span> -
                        <span class="interval">{{ endValue }}</span> sur
                        {{ totalValue }}
                      </li>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <li>
                        <button class="myBouton" @click="firstVal()" :disabled="startValue == 1" data-toggle="tooltip"
                          data-placement="bottom" title="Prem">
                          <ion-icon name="play-skip-back-outline"></ion-icon>
                        </button>
                        <button type="button" class="myBouton" @click="decrement()" :disabled="endValue == 500"
                          data-toggle="tooltip" data-placement="bottom" title="Préc">
                          <ion-icon name="chevron-back-outline"></ion-icon>
                        </button>
                        <button class="myBouton" @click="increment()" data-toggle="tooltip" data-placement="bottom"
                          title="Suiv" :disabled="endValue >= totalValue">
                          <ion-icon name="chevron-forward-outline"></ion-icon>
                        </button>
                        <button class="myBouton" @click="incrementToLast()" data-toggle="tooltip" data-placement="bottom"
                          title="Dern" :disabled="endValue >= totalValue">
                          <ion-icon name="play-skip-forward-outline"></ion-icon>
                        </button>
                      </li>
                    </ul>
                  </div>
                </transition>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <b-modal id="modal-center" size="xl" style="width: 100%" centered v-model="showMap"
      title="Position de la confirmation de Fin trajet" title-class="font-18" hide-footer modal-full>
      <div id="map" style="height:100%;">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <GmapMap :center="center" :zoom="zoom" map-type-id="terrain" style="width: 100%; height: 500px">
                <GmapMarker :position="markers.position" :clickable="true" :draggable="false"
                  @click="center = markers.position">

                  <GmapInfoWindow :label="markers.label">
                    <div v-if="isDriver == true" style="font-size: 1.2rem;">
                      Conducteur
                    </div>
                    <div v-if="isDriver == false" style="font-size: 1.2rem;">
                      Passager
                    </div>
                  </GmapInfoWindow>

                </GmapMarker>
              </GmapMap>
            </div>
          </div>
        </div>
        <div class="row float-right mt-3">
          <b-button variant="danger" @click="showMap = false">Fermer</b-button>
        </div>
      </div>

    </b-modal>
  </Layout>
</template>
<style>
thead {
  background: #3AAA35;
}


thead div {
  color: #fff;
}

#map {
  height: 100% !important;
}

.loading {
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
}

.loading {
  font-weight: bold;
  font-size: 1.001rem;
  width: 100%;
  white-space: nowrap;
  color: hsl(0, 0%, 12%);
  text-align: center;
  display: inline-block;
  margin: 0 auto !important;
}

.otherThing {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -55px;
}

.containerDot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
  width: 250px;
  height: 40px;
  position: absolute;
  top: -18%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

}

.input {

  right: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 250px;
  height: 35px;
  bottom: 0;
  flex: 60%;
  margin-bottom: 25px;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}

.input input:focus {
  border: 3px solid #d6d2f8;
}

.inputCol {
  position: relative;
  justify-content: flex-end;
  margin-right: 13px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}

.paging_simple_numbers {
  background: #3AAA35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, .2);
  padding: 10px 30px;
}

ion-icon {
  cursor: pointer;
}

.myBouton {
  background: transparent;
  text-align: center;
  outline: none;
  border: none;

}

.otherThing {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -55px;
}
</style>