<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";
/*import Multiselect from "vue-multiselect";
import { MazPhoneNumberInput } from "maz-ui";
import moment from "moment"; */
//import { format } from "date-fns";
//import router from "@/router/index.js";
//import image from "@/assets/images/users/user.png";

import {
  required,
  email,
  minLength,
  sameAs,
  maxLength,
  minValue,
  maxValue,
  numeric,
  url,
  alphaNum,
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Form Validation",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Urgences ponctuelles",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Urgences  ponctuelles",
          active: true,
        },
      ],
      ordersData: [
        {
          ordertrajet: "cotonou >> Parakou",
          user: "Nom & prénom",
          date: "28 Mar, 2020",
        },
      ],
      showPassword: false,
      phoneNumber: "",
      thenDay: "",
      id: "",
      form: {
        lastname: "",
        firstname: "",
        gender: "",
        state: "",
        zipcode: "",
        phoneNumber: "",
      },
      user: {
        firstname: "",
        lastname: "",
        gender: "",
        adress: "",
        profession: "",
        birthday: "",
        phoneNumber: "",
        email: "",
        password: "",
        about: "",
      },
      range: {
        minlen: "",
        maxlength: "",
        between: "",
        minval: "",
        maxval: "",
        rangeval: "",
        expr: "",
      },
      typeform: {
        name: "",
        password: "",
        confirmPassword: "",
        email: "",
        url: "",
        digit: "",
        number: "",
        alphanum: "",
        textarea: "",
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      currentPageTaxi: 1,
      perPageTaxi: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      filterOn: [],
      UrgencesData: [],
      courseUrgencesData: [],
      UrgencesDataDes: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "itinéraire", label: "Trajet" },
        { key: "sender", label: "Personne en danger" },
        { key: "tel", label: "Tel" },
        { key: "conducteur", label: "Conducteur" },
        { key: "telConducteur", label: "Tel" },
        { key: "date", sortable: true, label: "Date" },
        { key: "action", label: "Voir position" },
      ],
      fieldsAlertesDes: [
        { key: "itinéraire", label: "Trajet" },
        { key: "user", label: "Utilisateur" },
        { key: "date", sortable: true, label: "Date" },
      ],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      showDelete: false,
      showEdit: false,
      showdisable: false,
      startValue: 1,
      endValue: 500,
      totalValue: "0",
      userChargeLoader: false,
      newDatTrajetUrgence: [],
      newDatTrajetUrgenceTaxi: [],
      parBon: 500,
      recherche: ""


    };
  },
  validations: {
    form: {
      lastname: { required },
      firstname: { required },
      gender: { required },
      birthday: { required },
      profession: { required },
    },
    user: {
      firstname: { required },
      lastname: { required },
      gender: { required },
      phoneNumber: { required },
      email: { required, email },
      password: { required },
    },
    range: {
      minlen: { required, minLength: minLength(6) },
      maxlength: { required, maxLength: maxLength(6) },
      between: { required, minLength: minLength(5), maxLength: maxLength(10) },
      minval: { required, minValue: minValue(6) },
      maxval: { required, maxValue: maxValue(6) },
      rangeval: { required, minValue: minValue(6), maxValue: maxValue(100) },
      expr: { required },
    },
    typeform: {
      name: { required },
      password: { required, minLength: minLength(6) },
      confirmPassword: { required, sameAsPassword: sameAs("password") },
      email: { required, email },
      url: { required, url },
      digit: { required, numeric },
      number: { required, numeric },
      alphanum: { required, alphaNum },
      textarea: { required },
    },


  },

  async mounted() {
    this.init();
  },

  methods: {
    async init() {
      const urgenceTrajets = await apiRequest(
        "GET",
        "urgence",
        undefined,
        false
      );

      if (urgenceTrajets && urgenceTrajets.data) {

        console.log("urgenceTrajets.data", urgenceTrajets.data)

        const filtered = urgenceTrajets.data.filter(data => data.sender !== undefined).sort((a, b) => (b.id - a.id));
        console.log("filtered", filtered)
        filtered.forEach((urgences) => {
          let isCourse = false;
          if (urgences.type == 'trajet') {
            isCourse == false
            this.UrgencesData.push({
            
                id: urgences.idTrajet.id,
                conducteurId: urgences.conducteur.id,
                senderId: urgences.sender.personne.id,
                itinéraire: urgences.idTrajet.lieuDepart + " " + ">>" + " " + urgences.idTrajet.lieuArrivee,
                latitudeSender: urgences.latitudeSender,
                longitudeSender: urgences.longitudeSender,
                isVisible: urgences.idTrajet.isVisible,
                date: new Date(urgences.dateHeure).toLocaleString("fr-FR"),
                conducteur: urgences.conducteur != null ? urgences.conducteur.personne.firstname + " " + urgences.conducteur.personne.lastname : "",
                telConducteur: urgences.conducteur != null ? urgences.conducteur.personne.indicatifTel + "" + urgences.conducteur.personne.tel : "",
                sender: urgences.sender != null ? urgences.sender.personne.firstname + "" + urgences.sender.personne.lastname : "",
                tel: urgences.sender != null ? `${urgences.sender.personne.indicatifTel}${urgences.sender.personne.tel}` : "",
                course: isCourse,

              
          })
       
      } else if (urgences.type == 'course') {
        isCourse == true
        this.courseUrgencesData.push({
            
            id: urgences.idCourse.id,
            conducteurId: urgences.conducteur.id,
            senderId: urgences.sender.personne.id,
            itinéraire: urgences.idCourse.lieuDepart + " " + ">>" + " " + urgences.idCourse.lieuArrivee,
            latitudeSender: urgences.latitudeSender,
            longitudeSender: urgences.longitudeSender,
            isVisible: urgences.idCourse.isVisible,
            date: new Date(urgences.dateHeure).toLocaleString("fr-FR"),
            conducteur: urgences.conducteur != null ? urgences.conducteur.personne.firstname + " " + urgences.conducteur.personne.lastname : "",
            telConducteur: urgences.conducteur != null ? urgences.conducteur.personne.indicatifTel + "" + urgences.conducteur.personne.tel : "",
            sender: urgences.sender != null ? urgences.sender.personne.firstname + "" + urgences.sender.personne.lastname : "",
            tel: urgences.sender != null ? `${urgences.sender.personne.indicatifTel}${urgences.sender.personne.tel}` : "",
            course: isCourse,

          
      })
      }
    });

  }

  },


    async userSearch() {

  this.userChargeLoader = true;
  const dataSearch = await apiRequest(
    "GET",
    "urgence-recherche?mots=" + this.recherche,
    undefined,
    false
  );


  if (dataSearch && dataSearch.data) {
    const alerteUrgencePaginatedSearch = dataSearch.data.forEach((urgences) => {
          let isCourse = false;
          if (urgences.type == 'trajet') {
            isCourse == false
            this.newDatTrajetUrgence.push({
            
                id: urgences.idTrajet.id,
                conducteurId: urgences.conducteur.id,
                senderId: urgences.sender.personne.id,
                itinéraire: urgences.idTrajet.lieuDepart + " " + ">>" + " " + urgences.idTrajet.lieuArrivee,
                latitudeSender: urgences.latitudeSender,
                longitudeSender: urgences.longitudeSender,
                isVisible: urgences.idTrajet.isVisible,
                date: new Date(urgences.dateHeure).toLocaleString("fr-FR"),
                conducteur: urgences.conducteur != null ? urgences.conducteur.personne.firstname + " " + urgences.conducteur.personne.lastname : "",
                telConducteur: urgences.conducteur != null ? urgences.conducteur.personne.indicatifTel + "" + urgences.conducteur.personne.tel : "",
                sender: urgences.sender != null ? urgences.sender.personne.firstname + "" + urgences.sender.personne.lastname : "",
                tel: urgences.sender != null ? `${urgences.sender.personne.indicatifTel}${urgences.sender.personne.tel}` : "",
                course: isCourse,

              
          })
       
      } else if (urgences.type == 'course') {
        isCourse == true
        this.newDatTrajetUrgenceTaxi.push({
            
            id: urgences.idCourse.id,
            conducteurId: urgences.conducteur.id,
            senderId: urgences.sender.personne.id,
            itinéraire: urgences.idCourse.lieuDepart + " " + ">>" + " " + urgences.idCourse.lieuArrivee,
            latitudeSender: urgences.latitudeSender,
            longitudeSender: urgences.longitudeSender,
            isVisible: urgences.idCourse.isVisible,
            date: new Date(urgences.dateHeure).toLocaleString("fr-FR"),
            conducteur: urgences.conducteur != null ? urgences.conducteur.personne.firstname + " " + urgences.conducteur.personne.lastname : "",
            telConducteur: urgences.conducteur != null ? urgences.conducteur.personne.indicatifTel + "" + urgences.conducteur.personne.tel : "",
            sender: urgences.sender != null ? urgences.sender.personne.firstname + "" + urgences.sender.personne.lastname : "",
            tel: urgences.sender != null ? `${urgences.sender.personne.indicatifTel}${urgences.sender.personne.tel}` : "",
            course: isCourse,

          
      })
      }
    });

    

    this.userChargeLoader = false;

    this.totalRows = alerteUrgencePaginatedSearch.length
  }


},


// eslint-disable-next-line no-unused-vars
formSubmit(e) {
  //console.log("this.$v:", this.$v);
  this.submitted = true;
  // stop here if form is invalid
  this.$v.$touch();
  //this.create();
},

tooltipForm() {
  this.submitform = true;
  //this.submitted = true;
  this.formSubmit();
  this.$v.$touch();
},


onFiltered(filteredItems) {
  // Trigger pagination to update the number of buttons/pages due to filtering
  this.filteredData = filteredItems;
  this.totalRows = filteredItems.length;

},
/**
 * Range validation form submit
 */
// eslint-disable-next-line no-unused-vars
rangeform(e) {
  this.submit = true;
  // stop here if form is invalid
  this.$v.$touch();
},
/**
 * Validation type submit
 */
// eslint-disable-next-line no-unused-vars
typeForm(e) {
  this.typesubmit = true;
  // stop here if form is invalid
  this.$v.$touch();
},

goToSenderDetail(row) {
  //const routeData = router.resolve({name: 'infoUserPage', params:{idUser:id}});
  //router.push(`/user/user_info/${row.id}`);
  window.open(`/user/user_info/${row}`, '_blank', 'noreferrer');

  // window.open(routeData.href, '_blank');
},

goToDriverDetail(row){

  window.open(`/user/user_info/${row}`, '_blank', 'noreferrer');
},
    // goToUser(row) {
    //  
    //   //router.push(`/trajets/alertes/${row.id}`)
    //   //router.push('/user/user_info/'+row.id)
    // },
    async googleMap(row){
  window.open("http://www.google.com/maps/place/" + row.latitudeSender + "," + row.longitudeSender, '_blank');

},
desactivity(row) {
  this.showdisable = true;
  this.rowCall = row.id

},

    async desactiveAlerte(){
  this.showdisable = false;
  const returnAddSucess = await apiRequest(
    "POST",
    "admin/alerte-active",
    {
      id: this.rowCall,
      active: false,
    },
    false
  );
  if (returnAddSucess && returnAddSucess.data) {

    this.init();
  }
},
decrement() {
  this.userChargeLoader = true;

  this.startValue -= this.parBon;
  this.endValue -= this.parBon;

  this.actif(this.startValue, this.endValue);
},

increment() {
  this.userChargeLoader = true;
  this.startValue += this.parBon;
  this.endValue += this.parBon;

  this.actif(this.startValue, this.endValue);
},
incrementToLast() {
  this.userChargeLoader = true;
  this.startValue = this.totalValue - this.parBon;
  this.endValue = this.totalValue;

  this.actif(this.startValue, this.endValue);
},
firstVal() {
  this.userChargeLoader = true;
  this.startValue = 1;
  this.endValue = this.parBon;

  this.actif(this.startValue, this.endValue);
},
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="containerDot" v-if="UrgencesData.length == 0">
            <span class="loading">Chargement en cours... </span>
            <!--  <div class="dots">
              <div class="dot dot-1"></div>
              <div class="dot dot-2"></div>
              <div class="dot dot-2"></div>
            </div> -->
          </div>
          <div class="d-flex align-items-center otherThing" v-if="userChargeLoader === true">
            <span class="loading">Chargement en cours...</span>
            <div aria-hidden="true"></div>
          </div>
          <div class="card-body">

            <!-- <div class="row d-flex justify-content-between align-items-center p-2">
              <div>
                <h4 class="card-title mt-4">
                  Nombre total de trajets : {{ totalRows }}
                </h4>
              </div>
              
            </div> -->
            <form>
              <b-tabs nav-class="nav-tabs-custom">
                <b-tab title-link-class="p-3">
                  <template v-slot:title>
                    <a class="font-weight-bold active">Liste des urgences</a>
                  </template>
                  <div class="row  d-flex justify-content-between mt-4 lignSearch">
                    <div class="p-3">

                      <b-pagination
                      v-model="currentPage"
                      :total-rows="UrgencesData.length"
                      :per-page="perPage"
                      aria-controls="urgencesVTC"
                    ></b-pagination>

                    </div>
                    <!-- Search -->
                    <div class="inputCol p-3">
                      <div class="input ">
                        <input type="search" id="serchInput" placeholder="Rechercher..." v-model="recherche"
                          @keyup.enter="userSearch" />
                      </div>

                    </div>
                    <!-- End search -->
                  </div>
                  <div class="table-responsive mt-3">
                    <b-table class="table-centered"
                      :items="newDatTrajetUrgence.length != 0 ? newDatTrajetUrgence : UrgencesData" :fields="fields"
                      responsive="sm" :current-page="currentPage" :per-page="perPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                      :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
                      <template v-slot:cell(action)="row">
                        <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover @click="googleMap(row.item)"
                          title="Voir position">
                          <i class="fas fa-map-marker-alt font-size-18"></i>
                        </a>
                      </template>

                      <template v-slot:cell(conducteur)="row">
                        <div :key="index" @click="goToDriverDetail(row.item.conducteurId)">
                          {{ row.value }}  
                          <span >
                            <i
                            class="fas fa-eye text-success" > </i>

                          </span>
                          
                          

                        </div>
                      </template>

                      <template v-slot:cell(sender)="row">
                        <div @click="goToSenderDetail(row.item.senderId)">
                          {{ row.item.sender }} <i class="fas fa-eye"></i>
                        </div>
                      </template>


                      <template v-slot:cell(tel)="row">
                        <a :href="'https://wa.me/' + row.value" class="text-success" v-b-tooltip.hover
                          title="Envoyer un message whatsapp" target="_blank">
                          {{ row.value }}
                        </a>
                      </template>

                      <template v-slot:cell(telConducteur)="row">
                        <a :href="'https://wa.me/' + row.value" class="text-success" v-b-tooltip.hover
                          title="Envoyer un message whatsapp" target="_blank">
                          {{ row.value }}
                        </a>
                      </template>
                    </b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                    
                     
                    </div>
                  </div>
                </b-tab>
                <b-tab title-link-class="p-3">

                  
                  <template v-slot:title>
                    <a class="font-weight-bold active">Liste des urgences VTC</a>
                  </template>
                  <div class="row  d-flex justify-content-between mt-4 lignSearch">
                    <div class="p-3">

                      <b-pagination
                      v-model="currentPageTaxi"
                      :total-rows="courseUrgencesData.length"
                      :per-page="perPageTaxi"
                      aria-controls="urgencesVTC"
                    ></b-pagination>

                    </div>
                    <!-- Search -->
                    <div class="inputCol p-3">
                      <div class="input ">
                        <input type="search" id="serchInput" placeholder="Rechercher..." v-model="recherche"
                          @keyup.enter="userSearch" />
                      </div>

                    </div>
                    <!-- End search -->
                  </div>
                  <div class="table-responsive mt-3">
                    <b-table class="table-centered" id="urgencesVTC"
                      :items="newDatTrajetUrgenceTaxi.length != 0 ? newDatTrajetUrgenceTaxi : courseUrgencesData" :fields="fields"
                      responsive="sm" :current-page="currentPageTaxi" :per-page="perPageTaxi" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                      :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
                      <template v-slot:cell(action)="row">
                        <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover @click="googleMap(row.item)"
                          title="Voir position">
                          <i class="fas fa-map-marker-alt font-size-18"></i>
                        </a>
                      </template>

                      <template v-slot:cell(conducteur)="row">
                        <div :key="index" @click="goToDriverDetail(row.item.conducteurId)">
                          {{ row.value }} 
                          <span >
                            <i  class="fas fa-taxi" style="color: gold;"> </i>
                           
                         

                          </span>

                        </div>
                      </template>

                      <template v-slot:cell(sender)="row">
                        <div @click="goToSenderDetail(row.item.senderId)">
                          {{ row.item.sender }} <i class="fas fa-eye"></i>
                        </div>
                      </template>


                      <template v-slot:cell(tel)="row">
                        <a :href="'https://wa.me/' + row.value" class="text-success" v-b-tooltip.hover
                          title="Envoyer un message whatsapp" target="_blank">
                          {{ row.value }}
                        </a>
                      </template>

                      <template v-slot:cell(telConducteur)="row">
                        <a :href="'https://wa.me/' + row.value" class="text-success" v-b-tooltip.hover
                          title="Envoyer un message whatsapp" target="_blank">
                          {{ row.value }}
                        </a>
                      </template>
                    </b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                  
                      <transition mode="out-in">
                        <div class="dataTables_paginate paging_simple_numbers float-right">
                          <ul class="pagination pagination-rounded mb-0">
                            <!-- pagination -->
                            <li>
                              <span class="interval"> {{ startValue }}</span> -
                              <span class="interval">{{ endValue }}</span> sur
                              {{ totalValue }}
                            </li>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <li>
                              <button class="myBouton" @click="firstVal()" :disabled="startValue == 1"
                                data-toggle="tooltip" data-placement="bottom" title="Prem">
                                <ion-icon name="play-skip-back-outline"></ion-icon>
                              </button>
                              <button type="button" class="myBouton" @click="decrement()" :disabled="endValue == parBon"
                                data-toggle="tooltip" data-placement="bottom" title="Préc">
                                <ion-icon name="chevron-back-outline"></ion-icon>
                              </button>
                              <button class="myBouton" @click="increment()" data-toggle="tooltip"
                                data-placement="bottom" title="Suiv" :disabled="endValue >= totalRows">
                                <ion-icon name="chevron-forward-outline"></ion-icon>
                              </button>
                              <button class="myBouton" @click="incrementToLast()" data-toggle="tooltip"
                                data-placement="bottom" title="Dern" :disabled="endValue >= totalRows">
                                <ion-icon name="play-skip-forward-outline"></ion-icon>
                              </button>
                            </li>
                          </ul>
                        </div>
                      </transition>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </form>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="modal-backdrop" centered v-model="showdisable" title="Voulez-vous vraiment suspendre cet utilisateur ?"
      title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showdisable = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="desactiveAlerte">Oui</b-button>
      </div>

    </b-modal>
  </Layout>
</template>

<style>
#showPassword {
  width: 35px;
  height: 35px;
}


thead {
  background: #3AAA35;
}

thead div {
  color: #fff;
}


.input {

  right: 13px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 35px;
  top: 0;
  margin-top: 10px;

  flex: 60%;


  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}

.input input:focus {
  border: 3px solid #d6d2f8;
}

.lignSearch {
  position: relative;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}


.loading {
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
}

.loading {
  font-weight: bold;
  font-size: 1.001rem;
  width: 100%;
  white-space: nowrap;
  color: hsl(0, 0%, 12%);
  text-align: center;
  display: inline-block;
  margin: 0 auto !important;
}

.otherThing {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -50px;
}

.containerDot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
  width: 250px;
  height: 40px;
  position: absolute;
  top: -13%;
  left: 0;
  right: 0;
  margin: 0 auto;
}


ion-icon {
  cursor: pointer;
}

.myBouton {
  background: transparent;
  text-align: center;
  outline: none;
  border: none;
}

.containerDot {
  z-index: 100;
}

.paging_simple_numbers {
  background: #3AAA35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;

  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
}

.paging_simple_numbers ul li {
  color: white !important;
}


.paging_simple_numbers ul li button {
  color: #fff !important;
}
</style>
