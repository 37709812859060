<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";
import router from "@/router/index.js";

/**
 * Advanced table component
 */
export default {
  page: {
    title: "Trajets publiés",
    meta: [{ name: "Description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      tableData: [],
      title: "Trajets publiés",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Trajets publiés",
          active: true,
        },
      ],
      idtrajet: 0,
      totalRows: "0",
      currentPage: 1,
    
      pageOptions: [100, 250, 500],
      recherche: null,
      filterOn: [],
      sortBy: "dateHeureDepart",
      sortDesc: false,
      fields: [
        { key: "itinéraire", sortable: true },
        { key: "conducteur", sortable: true },
        { key: "tel", sortable: true },
        { key: "depart", sortable: true },
        { key: "nombrePlace", sortable: true, label: "Places" },
        { key: "prix", sortable: true },
        { key: "vues", sortable: true },
        { key: "date", sortable: true },
        { key: "passagers", sortable: true },
      ],
      startValue: 1,
      endValue: 500,
      totalValue: "0",
      userChargeLoader: false,
      newDatTrajetPublier: [],
      newStart: false,

    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  async mounted() {
    //Chargement des données
    const data = await apiRequest("GET", "admin/trajets", undefined, false);
    if (data && data.data) {
      const formattedTable = data.data.trajets.map((trajet) => {
        var passagers = "";
        this.totalValue=data.data.total

        trajet.passagers.forEach((element) => {
          if (element.statut == "ACTIF") {
            passagers +=
              "-" + element.firstname + " " + element.lastname + "\n";
          }
        });
        return {
          id: trajet.id,
          itinéraire:
            trajet.lieuDepart +
            " >> " +
            trajet.lieuArrivee,
          conducteur:
            trajet.conducteur.personne.lastname[0] +
            ". " +
            trajet.conducteur.personne.firstname,
          tel:
            trajet.conducteur.personne.indicatifTel +
            "" +
            trajet.conducteur.personne.tel,
          depart: new Date(trajet.dateHeureDepart).toLocaleString("fr-FR"),
          nombrePlace: trajet.nombrePlace,
          prix: trajet.prix + " XOF",
          date: new Date(trajet.dateCreation).toLocaleString("fr-FR"),
          passagers: passagers,
          vues: trajet.vues ? trajet.vues : 0,
        };
      });
      this.tableData = formattedTable;
      this.totalRows =  formattedTable.length;  
     
    }
    // Set the initial number of items
 
  },


  methods: {
    /**actif
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    goToDetail(row) {
      router.push(`/trajets/details_avenir/${row.id}`);
    },

    async actif(page, limit) {
      //Chargement des données

      const data = await apiRequest(
        "GET",
        "admin/trajets?page=" + page + "&limit=" + limit,
        undefined,
        false
      );
      if (data && data.data) {
        const formattedTable = data.data.trajets.map((trajet) => {
        var passagers = "";
        this.totalValue=data.data.total
        trajet.passagers.forEach((element) => {
          if (element.statut == "ACTIF") {
            passagers +=
              "-" + element.firstname + " " + element.lastname + "\n";
          }
        });
        return {
          id: trajet.id,
          itinéraire:
            trajet.lieuDepart.split(",")[0] +
            " >> " +
            trajet.lieuArrivee.split(",")[0],
          conducteur:
            trajet.conducteur.personne.lastname[0] +
            ". " +
            trajet.conducteur.personne.firstname,
          tel:
            trajet.conducteur.personne.indicatifTel +
            "" +
            trajet.conducteur.personne.tel,
          depart: new Date(trajet.dateHeureDepart).toLocaleString("fr-FR"),
          nombrePlace: trajet.nombrePlace,
          prix: trajet.prix + " XOF",
          date: new Date(trajet.dateCreation).toLocaleString("fr-FR"),
          passagers: passagers,
          vues: trajet.vues ? trajet.vues : 0,
        };
      });
        this.newDatTrajetPublier = formattedTable;
        this.rowsdataUser = formattedTable.length;
        this.totalRows =  formattedTable.length;  

        this.userChargeLoader = false;
      }
    },

    async userTotal() {
      const datatotal = await apiRequest(
        "GET",
        "admin/user-total",
        undefined,
        false
      );
      if (datatotal && datatotal.data) {
        this.totalValue = datatotal.data.total;
      }
    },

    async userSearch() {
     
      this.userChargeLoader = true;
      const dataSearch = await apiRequest(
        "POST",
        "admin/trajets/recherche?page="+ this.startValue + "&limit=" + this.endValue,

        { mots: this.recherche },
        false
      );
      if (dataSearch && dataSearch.data) {

        const formattedTable = dataSearch.data.map((trajet) => {
        var passagers = "";
       
        trajet.passagers.forEach((element) => {
          if (element.statut == "ACTIF") {
            passagers +=
              "-" + element.firstname + " " + element.lastname + "\n";
          }
        });
        return {
          id: trajet.id,
          itinéraire:
            trajet.lieuDepart.split(",")[0] +
            " >> " +
            trajet.lieuArrivee.split(",")[0],
          conducteur:
            trajet.conducteur.personne.lastname[0] +
            ". " +
            trajet.conducteur.personne.firstname,
          tel:
            trajet.conducteur.personne.indicatifTel +
            "" +
            trajet.conducteur.personne.tel,
          depart: new Date(trajet.dateHeureDepart).toLocaleString("fr-FR"),
          nombrePlace: trajet.nombrePlace,
          prix: trajet.prix + " XOF",
          date: new Date(trajet.dateCreation).toLocaleString("fr-FR"),
          passagers: passagers,
          vues: trajet.vues ? trajet.vues : 0,
        };
      });
        this.newDatTrajetPublier = formattedTable;
     
        this.totalRows =  this.newDatTrajetPublier.length;  
console.log("trouvé");
        this.userChargeLoader = false;
      }
    },

    decrement() {
      this.userChargeLoader = true;

      this.startValue -= 500;
      this.endValue -= 500;
     
      this.actif(this.startValue, this.endValue);
    },

    increment() {
      this.userChargeLoader = true;
      this.startValue += 500;
      this.endValue += 500;
     
      this.actif(this.startValue, this.endValue);
    },
    incrementToLast() {
      this.userChargeLoader = true;
      this.startValue = this.totalValue - 500;
      this.endValue = this.totalValue;
     
      this.actif(this.startValue, this.endValue);
    },
    firstVal() {
      this.userChargeLoader = true;
      this.startValue = 1;
      this.endValue = 500;
     
      this.actif(this.startValue, this.endValue);
    },



    
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="containerDot" v-if="tableData.length == 0">
            <span class="loading">Chargement en cours... </span>
            
          </div>
          <div
            class="d-flex align-items-center otherThing"
            v-if="userChargeLoader === true"
          >
            <span class="loading">Chargement en cours...</span>
            <div aria-hidden="true"></div>
          </div>
          <div class="card-body pt-0">
            <div
              class="row d-flex justify-content-between align-items-center p-2"
            >
              <div>
                <h4 class="card-title mt-4">
                  Nombre total de trajets : {{ totalRows }}
                </h4>
              </div>
              <transition mode="out-in">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <li>
                      <span class="interval"> {{ startValue }}</span> -
                      <span class="interval">{{ endValue }}</span> sur
                      {{ totalValue }}
                    </li>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <li>
                      <button
                        class="myBouton"
                        @click="firstVal()"
                        :disabled="startValue == 1"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Prem"
                      >
                        <ion-icon name="play-skip-back-outline"></ion-icon>
                      </button>
                      <button
                        type="button"
                        class="myBouton"
                        @click="decrement()"
                        :disabled="endValue == 500"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Préc"
                      >
                        <ion-icon name="chevron-back-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="increment()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Suiv"
                        :disabled="endValue >= totalValue"
                      >
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="incrementToLast()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Dern"
                        :disabled="endValue >= totalValue"
                      >
                        <ion-icon name="play-skip-forward-outline"></ion-icon>
                      </button>
                    </li>
                  </ul>
                </div>
              </transition>
            </div>

            <div class="row mt-4 d-flex justify-content-between">
              <div class="p-3"></div>
              <!-- Search -->
              <div class="inputCol pr-3">
                <div class="input">
                  <input
                    type="search"
                    id="serchInput"
                    placeholder="Rechercher..."
                    v-model="recherche"
                    @keyup.enter="userSearch"
                  />
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0 mt-2">
              <b-table
                :items="
                  newDatTrajetPublier.length != 0
                    ? newDatTrajetPublier
                    : tableData
                "
                :fields="fields"
                responsive="sm"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
            
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                @row-clicked="goToDetail"
              >
                <template v-slot:cell(tel)="row" style="text-align: center">
                  <a
                    :href="'https://wa.me/' + row.value"
                    class="text-success"
                    v-b-tooltip.hover
                    title="Envoyer un message whatsapp"
                    target="_blank"
                  >
                    {{ row.value }}
                  </a>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <!--   <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                      :aria-controls="trajet"
                    ></b-pagination>
                  </ul>
                </div> -->
                <transition mode="out-in">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <li>
                      <span class="interval"> {{ startValue }}</span> -
                      <span class="interval">{{ endValue }}</span> sur
                      {{ totalValue }}
                    </li>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <li>
                      <button
                        class="myBouton"
                        @click="firstVal()"
                        :disabled="startValue == 1"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Prem"
                      >
                        <ion-icon name="play-skip-back-outline"></ion-icon>
                      </button>
                      <button
                        type="button"
                        class="myBouton"
                        @click="decrement()"
                        :disabled="endValue == 500"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Préc"
                      >
                        <ion-icon name="chevron-back-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="increment()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Suiv"
                        :disabled="endValue >= totalValue"
                      >
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="incrementToLast()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Dern"
                        :disabled="endValue >= totalValue"
                      >
                        <ion-icon name="play-skip-forward-outline"></ion-icon>
                      </button>
                    </li>
                  </ul>
                </div>
              </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style>
thead {
  background: #3AAA35;
}thead div{
  color:#fff;
}
.input {
  right: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 345px;
  height: 35px;
  top: 0;
  right: -30;

  flex: 60%;

  margin: 0 0 10px;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}
.input input:focus {
  border: 3px solid #3aaa35;
}
.inputCol {
  position: relative;
  justify-content: flex-end;
  margin-right: 13px;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}

/*   Pagination System   */

.loading {
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
}

.loading {
  font-weight: bold;
  font-size: 1.001rem;
  width: 100%;
  white-space: nowrap;
  color: hsl(0, 0%, 12%);
  text-align: center;
  display: inline-block;
  margin: 0 auto !important;
}
.otherThing {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -50px;
}
.containerDot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
  width: 250px;
  height: 40px;
  position: absolute;
  top: -24%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.dots {
  position: relative;
  width: 100px;
  height: 50px;
  transform: scale(0.25, 0.25);
  margin-left: -10px;
}
.dot {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translate(0, -40%);
  opacity: 0;
  border-radius: 50%;
  background-color: hsl(0, 0%, 12%);
}

.dot-1 {
  animation: animation-dots 1.5s infinite linear 1s;
}
.dot-2 {
  animation: animation-dots 1.5s infinite linear 0.5s;
}
.dot-3 {
  animation: animation-dots 1.5s infinite linear;
}

@keyframes animation-dots {
  0% {
    left: -100px;
    opacity: 0;
  }
  20% {
    left: 0px;
    opacity: 1;
  }
  80% {
    left: 80px;
    opacity: 1;
  }
  100% {
    opacity: 0;
    left: 150px;
  }
}

.rowBase {
  position: relative;
}

ion-icon {
  cursor: pointer;
}

.myBouton {
  background: transparent;
  text-align: center;
  outline: none;
  border: none;
}

.containerDot {
  z-index: 100;
}

.paging_simple_numbers {
  background: #3AAA35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;

  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
} .paging_simple_numbers ul li{
  color:white!important;
}


.paging_simple_numbers ul li button{
  color:#fff!important;
}
</style>
