<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";
//import { format } from "date-fns";
import Multiselect from "vue-multiselect";
import router from "@/router/index.js";

/**
 * Advanced table component
 */
export default {
  page: {
    title: "Trajets à attribuer",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      tableData: [],
      title: "Trajets à attribuer",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Trajets à attribuer",
          active: true,
        },
      ],
      idtrajet: 0,
      totalRows: 0,
      currentPage: 1,


      filter: null,
      filterOn: [],
      sortBy: "dateHeureDepart",
      sortDesc: false,
      fields: [
        { key: "action", label: "Cocher" },
        { key: "itinéraire", sortable: true },
        { key: "conducteur", sortable: true },
        { key: "Depart", sortable: true },
        { key: "nombrePlace", sortable: true, label: "Places" },
        { key: "prix", sortable: true },
        { key: "passagers", sortable: true },
        { key: "date", sortable: true },
      ],
      idtrajetToUse: 0,
      user: null,
      showDelete: false,
      selected: [],
      selectAll: false,
      filteredData: [],
      users: [],
      idUser: 0,
      value: null,
      showConf: false,
      vehicules: [],
      showSuccess: false,
      showFailed: false,
      showConfDel: false,
      startValue: 1,
      endValue: 500,
      parBon: 500,
      totalValue: "0",
      userChargeLoader: false,
      newDatTrajetAvenir: [],
      newStart: false,
      recherche: "",
      libelLoader: "Chargement en cours...",
      myUsersToChoose: [],
      searchUser: "",
      selectedCar : []
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  async mounted() {
    //Chargement des données
    this.totalInfo();
    this.init();

  },
  methods: {
    async init() {
      //Chargement des données
      const data = await apiRequest(
        "GET",
        "admin/trajets/avenir",
        undefined,
        false
      );
      if (data && data.data) {
        this.totalValue = data.data.total;
        this.totalRows = data.data.total;
        const formattedTable = data.data.trajets.map((trajet) => {
          var passagers = "";
          trajet.passagers.forEach((element) => {
            if (element.statut == "ACTIF") {
              passagers +=
                "-" + element.firstname + " " + element.lastname + "\n";
            }
          });
          return {
            id: trajet.id,
            itinéraire:
              trajet.lieuDepart.nom.split(",")[0] +
              " >> " +
              trajet.lieuArrivee.nom.split(",")[0],
            conducteur:
              trajet.conducteur.personne.lastname[0] +
              ". " +
              trajet.conducteur.personne.firstname,
            Depart: new Date(trajet.dateHeureDepart).toLocaleString("fr-FR"),
            nombrePlace: trajet.nombrePlace,
            prix: trajet.prix + " XOF",
            date: new Date(trajet.dateCreation).toLocaleString("fr-FR"),
            passagers: passagers,
          };
        });
        this.tableData = formattedTable;
        this.totalRows = formattedTable.length
      }
      // Set the initial number of items

    },

    async userSearch() {
      this.userChargeLoader = true;
      const dataSearch = await apiRequest(
        "POST",
        "admin/trajets/recherche-avenir?page=" + this.startValue + "&limit=" + this.endValue,

        { mots: this.recherche },
        false
      );

      if (dataSearch && dataSearch.data) {
        const formattedTableseaech = dataSearch.data.map((trajet) => {
          var passagers = "";
          trajet.passagers.forEach((element) => {
            if (element.statut == "ACTIF") {
              passagers +=
                "-" + element.firstname + " " + element.lastname + "\n";
            }
          });
          return {
            id: trajet.id,
            itinéraire:
              trajet.lieuDepart.nom.split(",")[0] +
              " ↦ " +
              trajet.lieuArrivee.nom.split(",")[0],
            conducteur:
              trajet.conducteur.personne.lastname[0] +
              ". " +
              trajet.conducteur.personne.firstname,
            tel:
              trajet.conducteur.personne.indicatifTel +
              "" +
              trajet.conducteur.personne.tel,
            Depart: new Date(trajet.dateHeureDepart).toLocaleString("fr-FR"),
            nombrePlace: trajet.nombrePlace,
            prix: trajet.prix + " XOF",
            date: new Date(trajet.dateCreation).toLocaleString("fr-FR"),
            passagers: passagers,
            vues: trajet.vues ? trajet.vues : 0,
          };
        });

        this.newDatTrajetAvenir = formattedTableseaech;
        this.rowsdataUser = formattedTableseaech.length;
        this.totalRows = formattedTableseaech.length;
        this.userChargeLoader = false;
      }

    },

    async actif(page, limit) {
      //Chargement des données

      const data = await apiRequest(
        "GET",
        "admin/trajets/avenir?page=" + page + "&limit=" + limit,
        undefined,
        false
      );
      if (data && data.data) {
        const formattedTable = data.data.trajets.map((trajet) => {
          var passagers = "";
          trajet.passagers.forEach((element) => {
            if (element.statut == "ACTIF") {
              passagers +=
                "-" + element.firstname + " " + element.lastname + "\n";
            }
          });
          return {
            id: trajet.id,
            itinéraire:
              trajet.lieuDepart.nom.split(",")[0] +
              " ↦ " +
              trajet.lieuArrivee.nom.split(",")[0],
            conducteur:
              trajet.conducteur.personne.lastname[0] +
              ". " +
              trajet.conducteur.personne.firstname,
            tel:
              trajet.conducteur.personne.indicatifTel +
              "" +
              trajet.conducteur.personne.tel,
            Depart: new Date(trajet.dateHeureDepart).toLocaleString("fr-FR"),
            nombrePlace: trajet.nombrePlace,
            prix: trajet.prix + " XOF",
            date: new Date(trajet.dateCreation).toLocaleString("fr-FR"),
            passagers: passagers,
            vues: trajet.vues ? trajet.vues : 0,
          };
        });

        this.newDatTrajetAvenir = formattedTable;
        this.rowsdataUser = formattedTable.length;
        this.totalRows = formattedTable.length;
        this.userChargeLoader = false;
      }
    },
    async userListChoice() {
      //Chargement des données

      this.userChargeLoader = true;
      const dataSearch = await apiRequest(
        "POST",
        "admin/user-recherche",

        { mots: this.searchUser },
        false
      );
      if (dataSearch && dataSearch.data) {
        const formattedTable = dataSearch.data.map((user) => {
          //console.log('toch',user)
          return {
            id: user.id,
            name: user.lastname + " " + user.firstname,
            vehicules: user.vehicules ? user.vehicules : "pas de véhicules",

          };
        });
        this.myUsersToChoose = formattedTable;


        this.userChargeLoader = false;
      }
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.filteredData = filteredItems;
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    goToDetail(row) {
      //console.log('row:', row)
      router.push(`/trajets/details_avenir/${row.id}`);
      //router.push('/user/user_info/'+row.id)
    },
    cancelAction(row) {
      this.idtrajetToUse = row.id;

      this.showDelete = true;
    },
    async desactivetrajet() {
      (this.user = JSON.parse(localStorage.getItem("user"))),
        (this.showDelete = false);
      const data = await apiRequest(
        "POST",
        "admin/annuler-trajet",
        {
          idTrajet: this.idtrajetToUse,
          motifAnnulation:
            "Annuler par l'administrateur " +
            this.user.firstname +
            " " +
            this.user.lastname,
        },
        false
      );
      //console.log("data.data:", data);
      if (data && data.data) {
        this.init();


        this.$toast.success("Opérration effectuée avec succès !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });
        this.init();
      } else {
        this.$toast.success("Opération non effectuée", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });
      }
    },
    select() {
      if (this.filteredData.length == 0) {
        this.filteredData =
          this.newDatTrajetAvenir.length != 0
            ? this.newDatTrajetAvenir
            : this.tableData;

      }
      this.selected = new Array();
      if (!this.selectAll) {
        for (let i in this.filteredData) {
          this.selected.push(this.filteredData[i].id);

        }
      }
    },

    handleConf() {
      this.selectedcar = []
      this.vehicules = []

      this.vehicules = this.value.vehicules

      console.log("User", this.vehicules)
      this.showConf = true;
    },

    selectTarget(val) {
      //console.log(val)
      if (this.selectedCar[0] == val) {
        this.selectedCar = []
      } else {
        this.selectedCar = []
        this.selectedCar.push(val)
      }
      console.log("selectedCar",this.selectedCar)
    },
    isSelected(val) {
      return this.selectedCar.includes(val);
    },
    async attribuertrajet() {
      if (this.selectedCar.length == 0){

        this.$toast.error('Choix du véhicule obligatoire')

        return
      }
      let trajets = this.selected;
      let idUser = this.value.id;

      this.showConf = false;

      const data = await apiRequest(
        "POST",
        "admin/attribuer-trajet",
        {
          trajets: trajets,
          user: idUser,
          autoId:this.selectedCar[0],
        },
        false
      );
      if (data && data.data) {
        this.selected = [];
        this.selectAll = false;


        this.$toast.success("Opération effectuée avec succès !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });
        this.init();
      } else {


        this.$toast.error("Opération non effectuée ", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });
      }
    },
    async annulertrajet() {
      this.user = JSON.parse(localStorage.getItem("user"));

      let trajets = this.selected;

      this.showConfDel = false;

      const data = await apiRequest(
        "POST",
        "admin/annuler-trajets",
        {
          trajets: trajets,
          motifAnnulation:
            "Annuler par l'administrateur " +
            this.user.firstname +
            " " +
            this.user.lastname,
        },
        false
      );

      if (data && data.data) {
        this.selected = [];
        this.selectAll = false;


        this.$toast.success("Opération effectuée avec succès !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });
        this.init();
      } else {

        this.$toast.error("Opération non effectuée ", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });
      }
    },
    decrement() {
      this.userChargeLoader = true;

      this.startValue -= this.parBon;
      this.endValue -= this.parBon;

      this.actif(this.startValue, this.endValue);
    },

    increment() {
      this.userChargeLoader = true;
      this.startValue += this.parBon;
      this.endValue += this.parBon;

      this.actif(this.startValue, this.endValue);
    },
    incrementToLast() {
      this.userChargeLoader = true;
      this.startValue = this.totalValue - this.parBon;
      this.endValue = this.totalValue;

      this.actif(this.startValue, this.endValue);
    },
    firstVal() {
      this.userChargeLoader = true;
      this.startValue = 1;
      this.endValue = this.parBon;

      this.actif(this.startValue, this.endValue);
    },

    async totalInfo() {
      //Chargement des info
      const data = await apiRequest("GET", "admin/dash-info", undefined, false);
      if (data && data.data) {


        this.totalRows = data.data.totalUser;
      }

      //Location

    },

  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card backCard">
          <div class="containerDot" v-if="tableData.length == 0">
            <span class="loading">Chargement en cours... </span>
            
          </div>
          <div class="d-flex align-items-center otherThing" v-if="userChargeLoader === true">
            <span class="loading">Chargement en cours...</span>
            <div aria-hidden="true"></div>
          </div>
          <div class="card-body">


            <div class="row d-flex justify-content-between align-items-center p-2">
              <div>

                <h4 class="card-title">Trajets à attribuer : {{ this.totalRows }}</h4>

              </div>
              <transition mode="out-in">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">

                    <li>
                      <span class="interval"> {{ startValue }}</span> -
                      <span class="interval">{{ endValue }}</span> sur
                      {{ totalValue }}
                    </li>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <li>
                      <button class="myBouton" @click="firstVal()" :disabled="startValue == 1" data-toggle="tooltip"
                        data-placement="bottom" title="Prem">
                        <ion-icon name="play-skip-back-outline"></ion-icon>
                      </button>
                      <button type="button" class="myBouton" @click="decrement()" :disabled="endValue == 500"
                        data-toggle="tooltip" data-placement="bottom" title="Préc">
                        <ion-icon name="chevron-back-outline"></ion-icon>
                      </button>
                      <button class="myBouton" @click="increment()" data-toggle="tooltip" data-placement="bottom"
                        title="Suiv" :disabled="endValue >= this.totalValue">
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                      </button>
                      <button class="myBouton" @click="incrementToLast()" data-toggle="tooltip" data-placement="bottom"
                        title="Dern" :disabled="endValue >= totalValue">
                        <ion-icon name="play-skip-forward-outline"></ion-icon>
                      </button>
                    </li>
                  </ul>
                </div>
              </transition>
            </div>

            <div class="row mt-4 mb-4 d-flex justify-content-between">
              <div class="inputCol p-3">
                <div class="input">
                  <input type="search" id="serchInput" placeholder="Publication..." v-model="recherche"
                    @keyup.enter="userSearch" />
                </div>
              </div>
              <div class="form-group topMargin">
                <label class="form-group mb-2 checker">Choisir l'utilisateur :</label>
                <multiselect v-model="value" :options="myUsersToChoose" label="name" track-by="name"></multiselect>
              </div>
              <div class="inputCol p-3">
                <div class="input">
                  <input type="search" id="serchInput" placeholder="Rechercher un utilisateur" v-model="searchUser"
                    @keyup.enter="userListChoice" />
                </div>
              </div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->
          <div class="table-responsive mb-0">
            <div class="row d-flex align-items-center mb-3">
              <div class="col-md-4">
                <div class="row pl-4 d-flex align-items-center specialChecker">
                  <input type="checkbox" id="mycheck" v-model="selectAll" @click="select" />

                  <label class="form-checkbox mb-2 checker" for="mycheck">Tout cocher
                  </label>
                  <b-button class="ml-4" variant="danger" v-if="selected.length != 0"
                    @click="showConfDel = true">Supprimer</b-button>
                </div>
              </div>

              <div class="col-md-8">
                <div class="row d-flex align-items-center">



                  <b-button v-if="value != null && selected.length != 0" class="col-md-3" variant="warning"
                    @click="handleConf">Attribuer</b-button>

                </div>


              </div>
            </div>
            <!---->
            <div class="table-responsive mb-0 mt-3 p-2">
              <b-table :items="newDatTrajetAvenir.length != 0 ? newDatTrajetAvenir : tableData
      " :fields="fields" responsive="sm" class="table-centered" :current-page="currentPage"
                :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"
                @filtered="onFiltered" @row-clicked="goToDetail">
                <template v-slot:cell(action)="row">
                  <div class="specialChecker">
                    <input type="checkbox" :value="row.item.id" v-model="selected" />
                  </div>

                  <!--<a
                    href="javascript:void(0);"
                    class="text-danger"
                    v-b-tooltip.hover
                    @click="cancelAction(row.item)"
                    title="Désactiver"
                  >
                    <i class="mdi mdi-close font-size-18"></i>
                  </a>-->
                </template>
              </b-table>
            </div>
          </div>
          <div class="row">
            <div class="col">
           
              <transition mode="out-in">
                <div class="dataTables_paginate paging_simple_numbers float-right mb-2 mr-2">
                  <ul class="pagination pagination-rounded mb-0">

                    <li>
                      <span class="interval"> {{ startValue }}</span> -
                      <span class="interval">{{ endValue }}</span> sur
                      {{ totalValue }}
                    </li>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <li>
                      <button class="myBouton" @click="firstVal()" :disabled="startValue == 1" data-toggle="tooltip"
                        data-placement="bottom" title="Prem">
                        <ion-icon name="play-skip-back-outline"></ion-icon>
                      </button>
                      <button type="button" class="myBouton" @click="decrement()" :disabled="endValue == 500"
                        data-toggle="tooltip" data-placement="bottom" title="Préc">
                        <ion-icon name="chevron-back-outline"></ion-icon>
                      </button>
                      <button class="myBouton" @click="increment()" data-toggle="tooltip" data-placement="bottom"
                        title="Suiv" :disabled="endValue >= totalValue">
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                      </button>
                      <button class="myBouton" @click="incrementToLast()" data-toggle="tooltip" data-placement="bottom"
                        title="Dern" :disabled="endValue >= totalValue">
                        <ion-icon name="play-skip-forward-outline"></ion-icon>
                      </button>
                    </li>
                  </ul>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="modal-backdrop" centered v-model="showDelete" title="Voulez-vous vraiment annuler ce trajet ?"
      title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showDelete = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="desactivetrajet">Oui</b-button>
      </div>
    </b-modal>

    <!-- CONFIRMATION ATTRIBUTION -->
    <b-modal id="modal-backdrop" centered v-model="showConf"
      title="Voulez-vous vraiment attribuer les trajet.s sélectionné.s ?" title-class="font-18" hide-footer>


      <div class="p-3">
        <ul v-for="(item, index) in vehicules" :key="index">
          <li class="row d-flex align-items-center">

            <input type="checkbox" :value="item.id" :checked="isSelected(item.id)" @change="selectTarget(item.id)"
              class="mr-2"> <span> {{ item.autoMotoName  }}, {{ item.autoMotoImmatriculation  }}, {{ item.nombreSieges +"place(s)"  }}  </span>
          </li>
        </ul>
      </div>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showConf = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="attribuertrajet">Oui</b-button>
      </div>
    </b-modal>

    <!-- CONFIRMATION DELETE -->
    <b-modal id="modal-backdrop" centered v-model="showConfDel"
      title="Voulez-vous vraiment supprimer le.s trajet.s sélectionné.s ?" title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showConfDel = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="annulertrajet">Oui</b-button>
      </div>
    </b-modal>

    <!-- SUCCESS -->
  </Layout>
</template>
<style>
thead {
  background: #3AAA35 !important;
}

thead div {
  color: #fff;
}

.input {
  position: relative;
  display: flex;

  width: 300px;
  height: 35px;
  top: 0;

  margin: 0;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 100;
}

.input input:focus {
  border: 3px solid #3aaa35;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}

span.superuser {
  display: inline-flex;
  width: 20px;
  height: 20px;
  background-color: #fddb91;
  vertical-align: middle;
  border: none;
  box-shadow: -1px -1px 1px rgba(255, 255, 255, 0.95);
}

.dataTables_filter {
  padding-top: 0;
  margin-top: -10px;
}

input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  height: 40px;
  width: 40px;
  background-color: #d5d5d5;

  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  box-shadow: -10px -10px 15px rgba(255, 255, 255, 0.5),
    10px 10px 15px rgba(70, 70, 70, 0.12);
}

.checker {
  margin-top: 0.444rem;
  display: inline-flex;
  color: #4c4c4c;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  margin-left: 0.656rem;
  margin-bottom: 0;
}

.specialChecker input[type="checkbox"]:after {
  font-weight: 600;
  font-family: FontAwesome;
  content: "\f00c";

  font-size: 20px;
  color: white;
  display: none;
}

input[type="checkbox"]:after {
  font-weight: 600;
  font-family: FontAwesome;
  content: "\f00c";

  font-size: 20px;
  color: white;
  display: none;
}



.specialChecker input[type="checkbox"]:hover {
  background-color: #a5a5a5;
}

.specialChecker input[type="checkbox"]:checked {
  background-color: #5bcd3e;
}
input[type="checkbox"]:checked {
  background-color: #5bcd3e;
}

.specialChecker input[type="checkbox"]:checked:after {
  display: block;
}

.checkAll {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.inputCol {
  position: relative;
  box-sizing: border-box;

}

.loading {
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
}

.loading {
  font-weight: bold;
  font-size: 1.001rem;
  width: 100%;
  white-space: nowrap;
  color: hsl(0, 0%, 12%);
  text-align: center;
  display: inline-block;
  margin: 0 auto !important;
}

.otherThing {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -55px;
}

.containerDot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
  width: 250px;
  height: 40px;
  position: absolute;
  top: -14%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.dots {
  position: relative;
  width: 100px;
  height: 50px;
  transform: scale(0.25, 0.25);
  margin-left: -10px;
}

.dot {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translate(0, -40%);
  opacity: 0;
  border-radius: 50%;
  background-color: hsl(0, 0%, 12%);
}

.dot-1 {
  animation: animation-dots 1.5s infinite linear 1s;
}

.dot-2 {
  animation: animation-dots 1.5s infinite linear 0.5s;
}

.dot-3 {
  animation: animation-dots 1.5s infinite linear;
}

@keyframes animation-dots {
  0% {
    left: -100px;
    opacity: 0;
  }

  20% {
    left: 0px;
    opacity: 1;
  }

  80% {
    left: 80px;
    opacity: 1;
  }

  100% {
    opacity: 0;
    left: 150px;
  }
}

.rowBase {
  position: relative;
}

ion-icon {
  cursor: pointer;
}

.myBouton {
  background: transparent;
  text-align: center;
  outline: none;
  border: none;
}

/* .interval{
padding: 4px;
  height: 40px;
  min-width: 100px;
  width: 80px;
  text-align: center;
  color: #262626;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: inset 0 5px 10px rgba(0, 0, 0, .1), 0 2px 5px rgba(0, 0, 0, .5);
  margin: 5px;

} */
.paging_simple_numbers {
  background: #3AAA35 !important;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;

  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
}

.paging_simple_numbers ul li {
  color: white !important;
}


.paging_simple_numbers ul li button {
  color: #fff !important;
}


body {
  background: #ececec;
}


.topMargin {
  align-items: flex-end;
  margin-top: -20px;
}
</style>
