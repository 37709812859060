<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
// import Multiselect from "vue-multiselect";
// import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { apiRequest } from "@/helpers/api-call";
//import { format } from "date-fns";
import router from "@/router/index.js";
import Vue from "vue";


/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    // Multiselect,
    // ckeditor: CKEditor.component,
  },
  data() {
    return {
      title: "USERS VTC",
      items: [
        {
          text: "TAXI",
        },
        {
          text: "USERS VTC",
          active: true,
        },
      ],
      tyMedia: [
        {
          name: "Texte",
          value: "text",
        },
        {
          name: "Image",
          value: "image",
        },
        {
          name: "Vidéo",
          value: "video",
        },
      ],
      tyMessage: [
        {
          name: "Notification",
          value: 0,
        },
        {
          name: "Mail",
          value: 1,
        },
        {
          name: "Notification + Mail",
          value: 2,
        },
      ],

      userTypeList: [
        {
          id: "CONDUCTEUR",
          value: "MOTO",
        },
        {
          id: "PARTICULIER",
          value: "VOITURE",
        },
        {
          id: "TAXIMAN",
          value: "TAXI",
        },
        {
          id: "SOCIETE",

          value: "BUS",
        },
        { id: "PASSAGER", value: "PASSAGER" },
      ],
      url_link: "",
      userTypeSelect: "",
      messageSelect: null,
      mediaSelect: null,
      messageContent: [
        {
          sujet: "",
          message: "",
          userId: "",
        },
      ],
      value: null,
      value1: null,
      options: ["Sexe", "Taille", "Vehicule", "je vois"],
      idUser: 0,
      dataUser: [],
      dataUserInactif: [],
      dataUserSuspendu: [],
      dataUserD: [],
      rowsdataUser: 0,
      rowsdataUserD: 0,
      nbreUser: "",
      totalRows: 1,
      changeCurrent: 1,
      changePerPage: 100,
      currentPage: 1,
      currentPageD: 1,
      perPage: 100,
      perPageD: 100,
      pageOptions: [100, 250, 500],
      pageOptionsD: [100, 250, 500],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      paginatedDate: [],
      fields: [
        { key: "username", sortable: true, label: "Utilisateur" },
        { key: "tel", sortable: true, label: "Téléphone" },
        { key: "carType", sortable: true, label: "Type de course" },

        { key: "lieuService", sortable: true, label: "Lieu VTC" },
        { key: "lieuResidence", sortable: true, label: "Lieu de Résidence" },
        { key: "startSubs", sortable: true, label: "Début Abonnement" },

        { key: "status", sortable: true, label: "Statut" },
        { key: "action", label: "Actions" },
      ],
      fieldsInactif: [
        { key: "username", sortable: true, label: "Utilisateur" },
        { key: "tel", sortable: true, label: "Téléphone" },
        { key: "carType", sortable: true, label: "Type de course" },

        { key: "lieuService", sortable: true, label: "Lieu VTC" },
        { key: "lieuResidence", sortable: true, label: "Lieu de Résidence" },
        { key: "startSubs", sortable: true, label: "Début Abonnement" },

        { key: "status", sortable: true, label: "Statut" },
        { key: "action", label: "Actions" },
      ],
      fieldsSuspendu: [
        { key: "username", sortable: true, label: "Utilisateur" },
        { key: "tel", sortable: true, label: "Téléphone" },
        { key: "carType", sortable: true, label: "Type de course" },

        { key: "lieuService", sortable: true, label: "Lieu VTC" },
        { key: "lieuResidence", sortable: true, label: "Lieu de Résidence" },
        { key: "startSubs", sortable: true, label: "Début Abonnement" },

        { key: "status", sortable: true, label: "Statut" },
        { key: "action", label: "Actions" },
      ],
      startValue: 1,
      endValue: 500,
      totalValue: "0",
      showDisable: false,
      showEnable: false,
      showEdit: false,
      showSucess: false,
      showEchec: false,
      showSucessEdit: false,
      showEchecEdit: false,
      userToUse: 0,

      infoUser: {
        id: "",
        firstname: "",
        lastname: "",
        profession: "",
        birthday: "",
        adresse: "",
        ville: "",
        pays: "",
        userType: "",
        tel: "",
        email: "",
        personneUrgence: "",
        contactUrgence: "",
        name: "",
        boitePostal: "",
        apropos: "",
        permisCategorie: "",
        permisAnnee: "",
        permisNumuero: "",
        indicatifTel: "",
        indicatifContactUrg: "",
      },
      userTypeLocal: null,
      showMessage: false,
      showMessageMail: false,
      editor: ClassicEditor,
      editorData: "",
      deleteModal: false,
      userChargeLoader: false,
      newDataUser: [],
      newStart: false,
      recherche: "",
      userBox: [],
      confirmAlert:false

    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.dataUser.length;
    },
    rowsinactif() {
      return this.dataUserD.length;
    },
  },
  async mounted() {
    this.init();
    this.inactif();
    this.userTotal();
  },
  methods: {
    //GET /all-vtc-conductors

    async init() {
      //Chargement des données
      const data = await apiRequest("GET", "all-vtc-conductors", undefined, false);
      console.log("vtc driver", data)
      if (data && data.data) {
        const formattedTable = data.data.map((user) => {
          return {
            id: user.id,
            username: user.personne.lastname + " " + user.personne.firstname,
            firstname: user.personne.firstname,
            lastname: user.personne.lastname,
            tel: user.personne.indicatifTel && user.personne.tel != "" ? user.personne.indicatifTel + user.personne.tel : "",
            email: user.personne.email,
            dateIns: new Date(user.dateCreation).toLocaleString("fr-FR"),
            startSubs: new Date(user.last_abonnement.dateDebut).toLocaleString("fr-FR"),
            endSubs: new Date(user.last_abonnement.dateFin).toLocaleString("fr-FR"),
            amountSubs: user.last_abonnement.montant + "f",
            lieuResidence: user.personne.adresse ? user.personne.adresse : 'Adresse non définie',
            lieuService: user.personne.workPlaceName || "non défini",
            inscriptionDate: new Date(user.dateCreation).toLocaleString("fr-FR"),
            //type: type,
            status: user.last_abonnement.status,
            carType: user.type_vtc

          };
        });
        this.dataUser = formattedTable.filter(value => value.status === 'ACTIF');
        this.dataUserInactif = formattedTable.filter(value => value.status === 'INACTIF');
        this.dataUserSuspendu = formattedTable.filter(value => value.status === 'SUSPENDU');
        this.rowsdataUser = formattedTable.length;
        this.totalRows = formattedTable.length;

      }
    },

    async userSearch() {
      const value = (this.recherche.replace(/^\s+|\s+$/gm, '')).toLowerCase()
      console.log("INSIDE OF IT", value.replace(/^\s+|\s+$/gm, ''))
      this.newDataUser = []
      this.dataUser.forEach(el => {
        if (((el.username.trim()).toLowerCase()).includes(value.trim()) || (el.tel.trim()).includes(value.trim()) || ((el.lieuService.trim()).toLowerCase()).includes(value.trim())) {

          this.newDataUser.push(el)

        }

      })
    },

    async handleVTCService() {
      try {
        if (this.userBox.status == 'ACTIF') {
          this.showDisable = false;
          const request = await apiRequest('POST', 'action-on-vtc-driver', { id: this.userBox.id, status: "SUSPENDU", motif: "Désactivation du service VTC" })
          console.log(request.status)
          if (request.status == 200) {

            this.$toast.success("Opération réussi")
            console.log("request log", request.data)
            this.init()

          }
        } else {
          this.showEnable = false;
          if (this.userBox.status == 'SUSPENDU' || this.userBox.status == 'INACTIF') {


            const request = await apiRequest('POST', 'action-on-vtc-driver', { id: this.userBox.id, status: "ACTIF", motif: "Activation du service VTC" })
            console.log(request.status)
            if (request.status == 200) {

              this.$toast.success("Opération réussi")
              console.log("request log", request.data)
              this.init()

            }

          }


        }


      } catch (error) {
        console.log("error", error)
      }


    },

    activerUser(user) {

      this.showEnable = true;

      this.userBox = user;
    },

    desactiverUser(user) {

      this.showDisable = true;
      this.userBox = user;
    },

    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.badge === true) return "table-warning";
    },
    position() {
      Vue.swal({
        position: "center-end",
        icon: "success",
        title: "Mise à jour effectuée",
        showConfirmButton: false,
        timer: 3000,
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    goToDetail(row) {
      router.push(`/user/user_info/${row.id}`);
    },
    openNewTab(row) {
      window.open(`https://adminrmobility.raynis.co/user/user_info/${row.id}`, '_blank');
    },
    handleAlerte(){
      this.confirmAlert = true;
      
    },
    async sendAlert(){
      const response = await apiRequest('GET','mettez-vous-enligne');
      if (response.status ==200){
        this.confirmAlert = false
        this.$toast.success('Opération réussie');
      }
    }


  },

  watch: {

  },
};

/* const dots = document.querySelector('.loaading__dots')
  const addAnimate = ()=>{
    dots.classList.add('animate')
    setTimeout(()=>{
      dots.classList.remove('aniamte');
      setTimeout(()=>{
        addAnimate()
      },100)
    }, 2600)


    }
    addAnimate(); */
   
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row rowBase">
      <!-- ///mettez-vous-enligne -->



      <div class="col-lg-12">

        <div class="card homeContainer">
          <div class="containerDot" v-if="dataUser.length == 0 && newDataUser.length == 0">
            <span class="loading">Chargement en cours... </span>
            <!--  <div class="dots">
              <div class="dot dot-1"></div>
              <div class="dot dot-2"></div>
              <div class="dot dot-2"></div>
            </div> -->
          </div>
          <div class="d-flex align-items-center otherThing" v-if="userChargeLoader === true">
            <span class="loading">Chargement en cours...</span>
            <div aria-hidden="true"></div>
          </div>

          <div class="card-body pt-0">
            <!-- <div>
              <h4 class="card-title mt-4">
                Total d'utilisateurs : {{ totalRows }}
              </h4>
            </div> -->
            <div class="row my-4 py-4 d-flex justify-content-end">
              <button class="btn btn-success" @click="handleAlerte">
                Connectez-vous
              </button>
            </div>

            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Total d'utilisateurs : {{ totalRows }}
                  </a>
                </template>
                <div class="row d-flex align-items-center mt-4 mb-3">
                  <div class="col-md-3 text-left">
                    <!-- <span class="superuser mb-1"></span>&nbsp; Superuser -->
                  </div>
                  <div class="col-md-3">

                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div class="inputCol">
                      <!--   <label class="d-inline-flex align-items-center">
                        Rechercher:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-md ml-2"
                        ></b-form-input>
                      </label> -->

                      <div class="input mb-2">
                        <input type="search" id="serchInput" placeholder="Entrez un mot clé..." v-model="recherche"
                          @keyup.enter="userSearch()" @input="userSearch" />
                      </div>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive mt-2">

                  <div class="d-flex justify-content-start align-tems-center">



                    <b-pagination v-model="currentPage"
                      :total-rows="newDataUser.length != 0 ? newDataUser.length : dataUser.length" :per-page="perPage"
                      :limit="2" first-text="◀" last-text="▶" aria-controls="my-table">
                    </b-pagination>

                    <span class="d-block mt-2 mx-2"> {{ currentPage }} page sur {{ (newDataUser.length) != 0 ?
                      Math.ceil(newDataUser.length / perPage) : Math.ceil(dataUser.length / perPage) }}</span>


                  </div>

                  <b-table id="trajet" style="cursor: pointer" class="table-centered"
                    :items="newDataUser.length != 0 ? newDataUser : dataUser" :fields="fields" responsive="sm"
                    :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter" :per-page="perPage"
                    :current-page="currentPage" :filter-included-fields="filterOn" @filtered="onFiltered"
                    @row-clicked="goToDetail" :tbody-tr-class="rowClass">

                    <template v-slot:cell(username)="row">

                      <div>

                        {{ row.value }}
                        <!-- <span class="label label-warning">Taxi</span> -->
                        <!-- &nbsp;<span class="badge badge-pill badge-success m-2" style="font-size: 100%;background-color: goldenrod; color: #fff;" >Taxi</span> -->


                      </div>

                    </template>
                    <template v-slot:cell(badge)="row">
                      <div class="py-2 d-flex justify-content-center" :class="{
                        'fa fa-star': `${row.value}` === 'false',
                        'fa fa-star badge-soft-warning':
                          `${row.value}` === 'true',
                      }"></div>
                    </template>
                    <template v-slot:cell(status)="row">
                      <div class="py-2 d-flex justify-content-center badge font-size-12" :class="{
                        'badge-soft-warning': `${row.item.status}` === 'SUSPENDU',
                        'badge-soft-danger': `${row.item.status}` === 'INACTIF',
                        'badge-soft-success': `${row.item.status}` === 'ACTIF',
                      }">
                        {{ row.value }}
                      </div>
                    </template>
                    <template v-slot:cell(action)="row">
                      <div class="hover-gray text-center p-2">

                        <span v-if="row.item.status == 'ACTIF'" class="
                        mx-auto">

                          <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover
                            @click="desactiverUser(row.item)" title="Désactiver VTC">
                            <i class="fas fa-times"></i>
                          </a>
                        </span>
                        <span v-else-if="row.item.status == 'SUSPENDU'" class="mx-auto">
                          <a href="javascript:void(0);" class="text-success" v-b-tooltip.hover
                            @click="activerUser(row.item)" title="Activer VTC">
                            <i class="fas fa-check"></i>
                          </a>
                        </span>

                        <span v-else-if="row.item.status == 'INACTIF'" class="mx-auto">
                          <a href="javascript:void(0);" class="text-success" v-b-tooltip.hover
                            @click="activerUser(row.item)" title="Activer VTC">
                            <i class="fas fa-check"></i>
                          </a>
                        </span>
                      </div>
                    </template>

                    <template v-slot:cell(tel)="row">
                      <a :href="'https://wa.me/' + row.value" class="text-success" v-b-tooltip.hover
                        title="Envoyer un message whatsapp" target="_blank">
                        {{ row.value }}
                      </a>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">

                    <div class="d-flex justify-content-end align-tems-center">
                      <span class="d-block mt-2 mx-2"> {{ currentPage }} sur {{ (newDataUser.length) != 0 ?
                        Math.ceil(newDataUser.length /
                          perPage) : Math.ceil(dataUser.length / perPage) }}</span>


                      <b-pagination v-model="currentPage"
                        :total-rows="newDataUser.length != 0 ? newDataUser.length : dataUser.length" :per-page="perPage"
                        :limit="2" first-text="◀" last-text="▶" aria-controls="my-table">
                      </b-pagination>

                    </div>
                  </div>
                </div>

              </b-tab>
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Total d'utilisateurs Inactif : {{ dataUserInactif.length }}
                  </a>
                </template>
                <div class="row d-flex align-items-center mt-4 mb-3">
                  <div class="col-md-3 text-left">
                    <!-- <span class="superuser mb-1"></span>&nbsp; Superuser -->
                  </div>
                  <div class="col-md-3">

                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div class="inputCol">
                      <!--   <label class="d-inline-flex align-items-center">
                        Rechercher:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-md ml-2"
                        ></b-form-input>
                      </label> -->

                      <div class="input mb-2">
                        <input type="search" id="serchInput" placeholder="Entrez un mot clé..." v-model="recherche"
                          @keyup.enter="userSearch()" @input="userSearch" />
                      </div>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive mt-2">

                  <div class="d-flex justify-content-start align-tems-center">



                    <b-pagination v-model="currentPageInactif"
                      :total-rows="newDataUser.length != 0 ? newDataUser.length : dataUserInactif.length"
                      :per-page="perPage" :limit="2" first-text="◀" last-text="▶" aria-controls="my-table">
                    </b-pagination>

                    <span class="d-block mt-2 mx-2"> {{ currentPageInactif }} page sur {{ (newDataUser.length) != 0 ?
                      Math.ceil(newDataUser.length / perPage) : Math.ceil(dataUserInactif.length / perPage) }}</span>


                  </div>

                  <b-table id="trajet" style="cursor: pointer" class="table-centered"
                    :items="newDataUser.length != 0 ? newDataUser : dataUserInactif" :fields="fields" responsive="sm"
                    :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter" :per-page="perPage"
                    :current-page="currentPageInactif" :filter-included-fields="filterOn" @filtered="onFiltered"
                    @row-clicked="goToDetail" :tbody-tr-class="rowClass">

                    <template v-slot:cell(username)="row">

                      <div>

                        {{ row.value }}
                        <!-- <span class="label label-warning">Taxi</span> -->
                        <!-- &nbsp;<span class="badge badge-pill badge-success m-2" style="font-size: 100%;background-color: goldenrod; color: #fff;" >Taxi</span> -->


                      </div>

                    </template>
                    <template v-slot:cell(badge)="row">
                      <div class="py-2 d-flex justify-content-center" :class="{
                        'fa fa-star': `${row.value}` === 'false',
                        'fa fa-star badge-soft-warning':
                          `${row.value}` === 'true',
                      }"></div>
                    </template>
                    <template v-slot:cell(status)="row">
                      <div class="py-2 d-flex justify-content-center badge font-size-12" :class="{
                        'badge-soft-warning': `${row.item.status}` === 'SUSPENDU',
                        'badge-soft-danger': `${row.item.status}` === 'INACTIF',
                        'badge-soft-success': `${row.item.status}` === 'ACTIF',
                      }">
                        {{ row.value }}
                      </div>
                    </template>
                    <template v-slot:cell(action)="row">
                      <div class="hover-gray text-center p-2">

                        <span v-if="row.item.status == 'ACTIF'" class="
                        mx-auto">

                          <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover
                            @click="desactiverUser(row.item)" title="Désactiver VTC">
                            <i class="fas fa-times"></i>
                          </a>
                        </span>
                        <span v-else-if="row.item.status == 'SUSPENDU'" class="mx-auto">
                          <a href="javascript:void(0);" class="text-success" v-b-tooltip.hover
                            @click="activerUser(row.item)" title="Activer VTC">
                            <i class="fas fa-check"></i>
                          </a>
                        </span>

                        <span v-else-if="row.item.status == 'INACTIF'" class="mx-auto">
                          <a href="javascript:void(0);" class="text-success" v-b-tooltip.hover
                            @click="activerUser(row.item)" title="Activer VTC">
                            <i class="fas fa-check"></i>
                          </a>
                        </span>
                      </div>
                    </template>

                    <template v-slot:cell(tel)="row">
                      <a :href="'https://wa.me/' + row.value" class="text-success" v-b-tooltip.hover
                        title="Envoyer un message whatsapp" target="_blank">
                        {{ row.value }}
                      </a>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">

                    <div class="d-flex justify-content-end align-tems-center">
                      <span class="d-block mt-2 mx-2"> {{ currentPageInactif }} sur {{ (newDataUser.length) != 0 ?
                        Math.ceil(newDataUser.length /
                          perPage) : Math.ceil(dataUserInactif.length / perPage) }}</span>


                      <b-pagination v-model="currentPageInactif"
                        :total-rows="newDataUser.length != 0 ? newDataUser.length : dataUserInactif.length"
                        :per-page="perPage" :limit="2" first-text="◀" last-text="▶" aria-controls="my-table">
                      </b-pagination>

                    </div>
                  </div>
                </div>

              </b-tab>
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Total d'utilisateurs Suspendu : {{ dataUserSuspendu.length }}
                  </a>
                </template>
                <div class="row d-flex align-items-center mt-4 mb-3">
                  <div class="col-md-3 text-left">
                    <!-- <span class="superuser mb-1"></span>&nbsp; Superuser -->
                  </div>
                  <div class="col-md-3">

                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div class="inputCol">
                      <!--   <label class="d-inline-flex align-items-center">
                        Rechercher:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-md ml-2"
                        ></b-form-input>
                      </label> -->

                      <div class="input mb-2">
                        <input type="search" id="serchInput" placeholder="Entrez un mot clé..." v-model="recherche"
                          @keyup.enter="userSearch()" @input="userSearch" />
                      </div>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive mt-2">

                  <div class="d-flex justify-content-start align-tems-center">



                    <b-pagination v-model="currentPageSuspendu"
                      :total-rows="newDataUser.length != 0 ? newDataUser.length : dataUserSuspendu.length"
                      :per-page="perPage" :limit="2" first-text="◀" last-text="▶" aria-controls="my-table">
                    </b-pagination>

                    <span class="d-block mt-2 mx-2"> {{ currentPageSuspendu }} page sur {{ (newDataUser.length) != 0 ?
                      Math.ceil(newDataUser.length / perPage) : Math.ceil(dataUserSuspendu.length / perPage) }}</span>


                  </div>

                  <b-table id="trajet" style="cursor: pointer" class="table-centered"
                    :items="newDataUser.length != 0 ? newDataUser : dataUserSuspendu" :fields="fields" responsive="sm"
                    :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter" :per-page="perPage"
                    :current-page="currentPageSuspendu" :filter-included-fields="filterOn" @filtered="onFiltered"
                    @row-clicked="goToDetail" :tbody-tr-class="rowClass">

                    <template v-slot:cell(username)="row">

                      <div>

                        {{ row.value }}
                        <!-- <span class="label label-warning">Taxi</span> -->
                        <!-- &nbsp;<span class="badge badge-pill badge-success m-2" style="font-size: 100%;background-color: goldenrod; color: #fff;" >Taxi</span> -->


                      </div>

                    </template>
                    <template v-slot:cell(badge)="row">
                      <div class="py-2 d-flex justify-content-center" :class="{
                        'fa fa-star': `${row.value}` === 'false',
                        'fa fa-star badge-soft-warning':
                          `${row.value}` === 'true',
                      }"></div>
                    </template>
                    <template v-slot:cell(status)="row">
                      <div class="py-2 d-flex justify-content-center badge font-size-12" :class="{
                        'badge-soft-warning': `${row.item.status}` === 'SUSPENDU',
                        'badge-soft-danger': `${row.item.status}` === 'INACTIF',
                        'badge-soft-success': `${row.item.status}` === 'ACTIF',
                      }">
                        {{ row.value }}
                      </div>
                    </template>
                    <template v-slot:cell(action)="row">
                      <div class="hover-gray text-center p-2">

                        <span v-if="row.item.status == 'ACTIF'" class="
                        mx-auto">

                          <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover
                            @click="desactiverUser(row.item)" title="Désactiver VTC">
                            <i class="fas fa-times"></i>
                          </a>
                        </span>
                        <span v-else-if="row.item.status == 'SUSPENDU'" class="mx-auto">
                          <a href="javascript:void(0);" class="text-success" v-b-tooltip.hover
                            @click="activerUser(row.item)" title="Activer VTC">
                            <i class="fas fa-check"></i>
                          </a>
                        </span>

                        <span v-else-if="row.item.status == 'INACTIF'" class="mx-auto">
                          <a href="javascript:void(0);" class="text-success" v-b-tooltip.hover
                            @click="activerUser(row.item)" title="Activer VTC">
                            <i class="fas fa-check"></i>
                          </a>
                        </span>
                      </div>
                    </template>

                    <template v-slot:cell(tel)="row">
                      <a :href="'https://wa.me/' + row.value" class="text-success" v-b-tooltip.hover
                        title="Envoyer un message whatsapp" target="_blank">
                        {{ row.value }}
                      </a>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">

                    <div class="d-flex justify-content-end align-tems-center">
                      <span class="d-block mt-2 mx-2"> {{ currentPageSuspendu }} sur {{ (newDataUser.length) != 0 ?
                        Math.ceil(newDataUser.length /
                          perPage) : Math.ceil(dataUserSuspendu.length / perPage) }}</span>


                      <b-pagination v-model="currentPageSuspendu"
                        :total-rows="newDataUser.length != 0 ? newDataUser.length : dataUserSuspendu.length"
                        :per-page="perPage" :limit="2" first-text="◀" last-text="▶" aria-controls="my-table">
                      </b-pagination>

                    </div>
                  </div>
                </div>

              </b-tab>

            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="modal-backdrop" centered v-model="showDisable"
      title="Voulez-vous vraiment désactiver le VTC pour ce chauffeur ?" title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showDisable = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="handleVTCService">Oui</b-button>
      </div>
    </b-modal>
    <b-modal id="modal-backdrop" centered v-model="showEnable"
      title="Voulez-vous vraiment activer le VTC pour ce chauffeur ?" title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showEnable = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="handleVTCService">Oui</b-button>
      </div>
    </b-modal>
    <b-modal id="modal-backdrop" centered v-model="confirmAlert"
      title="Voulez-vous notifier tous les Assos ?" title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="confirmAlert = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="sendAlert()">Oui</b-button>
      </div>
    </b-modal>


    <!-- ENVOYER LE MESSAGE NOTIF-->

    <!-- -->
  </Layout>
</template>

<style>
thead {
  background: #3AAA35;
}

thead div {
  color: #fff;
}

thead th:first-child {
  font-weight: 600 !important;
}

thead th span {
  color: #fff;
}

.b-pagination .active .page-link {
  background-color: #3AAA3A !important;
}

.hover-gray:hover {
  background-color: #cccccc3d;
}

.nav-tabs .nav-link {
  background: #dc143c;
  transition: all .5s;
}

.nav-tabs .nav-link a {
  color: #fff;
}

.text-red {
  color: #dc143c;
}

.nav-tabs .nav-link a {
  color: #fff !important;
}

.text-green {
  color: #3AAA35;
}

.nav-tabs .nav-link a {
  color: rgba(44, 56, 74, 0.95);
}

.nav-tabs-custom .nav-item .nav-link.active a {
  color: #fff !important;
  font-weight: 600 !important;
  vertical-align: middle;
}

.nav-tabs-custom .nav-item .nav-link.active {
  font-weight: 600;
  background: #3AAA35 !important;
  border-bottom: 1px solid #000000 !important;
}

.nav-tabs-custom .nav-item .nav-link::after {
  border-bottom: 1px solid #000000 !important;
}

/*table filter design*/

.input {

  width: 300px;
  height: 35px;
  position: relative;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}

.input input:focus {
  border: 3px solid #3aaa35;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}

span.superuser {
  display: inline-flex;
  width: 20px;
  height: 20px;
  background-color: #fddb91;
  vertical-align: middle;
  border: none;
  box-shadow: -1px -1px 1px rgba(255, 255, 255, 0.95);
}

.dataTables_filter {
  padding-top: 0;
  margin-top: -10px;
}

.inputCol {
  position: relative;
  float: right;
}

.paging_simple_numbers {
  background: #3AAA35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;

  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
}

.paging_simple_numbers ul li {
  color: white !important;
}


.paging_simple_numbers ul li button {
  color: #fff !important;
}

.pagination ul {
  position: relative;
  display: flex;
  padding: 10px 20px;
  align-items: center;
}

.myIcon {
  margin-top: 2px;
}

.loading__dots {
  display: inline-flex;
  column-gap: 0.25rem;
}

.loading__dot {
  position: relative;
  width: 8px;
  height: 8px;
  background-color: hsl(0, 0%, 12%);
  border-radius: 50%;
}

.loading__dot:nth-child(1) {
  position: absolute;
  transform: scale(0);
}

.loading__dot:nth-child(4) {
  background: transparent;
}

.loading__dot-down {
  display: block;
  width: 8px;
  height: 8px;
  background-color: hsl(0, 0%, 12%);
  border-radius: 50%;
  transform: translate(0);
}

.animate .loading__dot:nth-child(1) {
  animation: scale-dot 0.8s 0.2s forwards;
}

.animate .loading__dot:nth-child(2),
.animate .loading__dot:nth-child(3) {
  animation: move-right 0.6s forwards;
}

.animate .loading__dot-down {
  animation: move-right-down 0.8s 0.2s forwards linear,
    move-down 2.8s 0.3s forwards ease-in;
}

@keyframes scale-dot {
  100% {
    transform: scale(1);
  }
}

@keyframes move-right {
  100% {
    transform: translateX (0.75rem);
  }
}

@keyframes move-right-down {
  50% {
    transform: translate(1.5rem, 0.25rem);
  }

  100% {
    transform: translate(2rem, 0.5rem);
  }
}

@keyframes move-down {
  100% {
    transform: translate(10rem, 80vh);
  }
}

.loading {
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
}

.loading {
  font-weight: bold;
  font-size: 1.001rem;
  width: 100%;
  white-space: nowrap;
  color: hsl(0, 0%, 12%);
  text-align: center;
  display: inline-block;
  margin: 0 auto !important;
}

.otherThing {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -55px;
}

.homeContainer {
  position: relative;

}

.containerDot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
  width: 250px;
  height: 40px;
  position: absolute;
  top: 2%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.dots {
  position: relative;
  width: 100px;
  height: 50px;
  transform: scale(0.25, 0.25);
  margin-left: -10px;
}

.dot {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translate(0, -40%);
  opacity: 0;
  border-radius: 50%;
  background-color: hsl(0, 0%, 12%);
}

.dot-1 {
  animation: animation-dots 1.5s infinite linear 1s;
}

.dot-2 {
  animation: animation-dots 1.5s infinite linear 0.5s;
}

.dot-3 {
  animation: animation-dots 1.5s infinite linear;
}

@keyframes animation-dots {
  0% {
    left: -100px;
    opacity: 0;
  }

  20% {
    left: 0px;
    opacity: 1;
  }

  80% {
    left: 80px;
    opacity: 1;
  }

  100% {
    opacity: 0;
    left: 150px;
  }
}

.rowBase {
  position: relative;
}

ion-icon {
  cursor: pointer;
}

.myBouton {
  background: transparent;
  text-align: center;
  outline: none;
  border: none;
}

/* .interval{
padding: 4px;
  height: 40px;
  min-width: 100px;
  width: 80px;
  text-align: center;
  color: #262626;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: inset 0 5px 10px rgba(0, 0, 0, .1), 0 2px 5px rgba(0, 0, 0, .5);
  margin: 5px;

} */

.containerDot {
  z-index: 100;
}

.pac-container {
  z-index: 10000 !important;
}

/** Conducteur option */

.center input[type="checkbox"] {
  position: relative;
  width: 45px;
  min-width: 45px;
  height: 20px;
  -webkit-appearance: none;
  background: #c6c6c6;
  outline: none;
  border-radius: 20px;
  transition: -5px;
  cursor: pointer;
}

.center input:checked[type="checkbox"] {
  background: linear-gradient(0deg, #00d22d, #717171);
}

.center input[type="checkbox"]:before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: 0;
  left: 0;
  background: #fff;
  transform: scale(1, 1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
  color: #d6d2f8;
}

.center input:checked[type="checkbox"]:before {
  left: 25px;
}

.center {
  display: flex;
  align-items: center;
}

.mode {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: #495057;
}


.multiselect__tags {
  min-height: 50px !important;
  height: 50px;
}
</style>
