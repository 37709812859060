<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { debounce } from 'lodash';
import GmapCustomMarker from 'vue2-gmap-custom-marker';
import { apiRequest } from "@/helpers/api-call";
import gsap from 'gsap';
//import Vue from "vue";

/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    'gmap-custom-marker': GmapCustomMarker,
  },
  data() {
    return {
      title: "VUE GLOBALE",
      items: [
        {
          text: "Dashboard",
        },
        {
          text: "VUE GLOBALE",
          active: true,
        },
      ],
      debouncedUpdateCarRotation: debounce(this.updateCarRotation, 10000),// 100ms de délai
      url_link: "",
      paginatedDate: [],
      fields: [
        { key: "driver", sortable: true, label: "Conducteur" },
        { key: "driverPhone", sortable: true, label: "Tel Conducteur" },
        { key: "passenger", sortable: true, label: "Passager" },
        { key: "passengerPhone", sortable: true, label: "Tel Passager" },
        { key: "startPlace", sortable: true, label: "Lieu de départ" },
        { key: "endPlace", sortable: true, label: "Lieu d'arrivé" },
        { key: "rideRequestTime", sortable: true, label: "Date de course" },
        { key: "waitingStart", sortable: true, label: "Debut d'attente" },
        { key: "waitingDelay", sortable: true, label: "Durée d'attente" },
        { key: "startTime", sortable: true, label: "Début de la course" },
        { key: "rideEstimCost", sortable: true, label: "Prix Estimé" },
        { key: "rideFinalCost", sortable: true, label: "Prix Final" },
        { key: "statut", sortable: true, label: "Statut" },
        { key: "suivi", sortable: true, label: "Position en direct" },

        // { key: "action", label: "Actions" },
      ],
      map: null,
      markerLatLng: { lat: 6.3633, lng: 2.4300 },
      center: { lat: 6.3633, lng: 2.4300 },
      zoom: 13,
      markers: [
      ],
      markersEncours: [
      ],
      markersLive: [],
      lat: 6.3633,
      lng: 2.4300,
      socket: null,
      selectedRideId: null,
      markerPosition: [],
      markerPositionSaved: { lat: 0, lng: 0 }, // Initial position of the marker
      isDriver: true, // Assuming driver by default
      carIcon: { url: require('@/assets/images/placeholders/basicIcon.png'), scaledSize: { width: 30, height: 57 } },
      vipIcon: { url: require('@/assets/images/placeholders/vipIcon.png'), scaledSize: { width: 30, height: 57 } },
      zemIcon: { url: require('@/assets/images/placeholders/zemIcon.png'), scaledSize: { width: 30, height: 57 } },
      basicIcon: { url: require('@/assets/images/placeholders/basicIcon.png'), scaledSize: { width: 30, height: 57 } },
      ecoIcon: { url: require('@/assets/images/placeholders/ecoIcon.png'), scaledSize: { width: 30, height: 57 } },
      comfortIcon: { url: require('@/assets/images/placeholders/comfortIcon.png'), scaledSize: { width: 30, height: 57 } },
      tryIcon: { url: require('@/assets/images/placeholders/tryIcon.png'), scaledSize: { width: 30, height: 57 } },
      fetchPositionsInterval: null,
      fetchLivePositionsInterval: null,
      openedMarkerID: null,
    };
  },
  computed: {


    /**
     * Total no. of records
     */
    rows() {
      return this.processingRide.length;
    },
    rowsinactif() {
      return this.dataUserD.length;
    },
  },
  mounted() {

    this.init();
    this.fetchPositionsInterval = setInterval(this.fetchDriverPositions, 21000); // Fetch positions every 5 seconds
    this.fetchLivePositionsInterval = setInterval(this.liveDriver, 21000); // Fetch positions every 5 seconds
  },


  methods: {

    openMarker(id) {
      this.openedMarkerID = id;
    },

    async liveDriver() {

      try {
        const response = await apiRequest('GET', 'realtime-conducteurs');
        console.log("Réponse du serveur pour les conducteurs en ligne 3", response);
        if (response) {

          if (response.status == 200) {
            this.markersLive = []
            //console.log("driver live", response.data)
            response.data.forEach(
              conducteur => {
                var checkedIcon = '';


                if (conducteur.type == "VIP") {
                  checkedIcon = this.vipIcon

                } else if (conducteur.type == "BASIC") {
                  checkedIcon = this.basicIcon

                }
                else if (conducteur.type == "CONFORT") {
                  checkedIcon = this.comfortIcon

                } else if (conducteur.type == "ZEM") {
                  checkedIcon = this.zemIcon

                } else if (conducteur.type == "TRICYCLE") {
                  checkedIcon = this.tryIcon
                } else if (conducteur.type == "ECO") {
                  checkedIcon = this.ecoIcon
                }

                ////console.log("conducteur", conducteur);
                const marker = {
                  position: { lat: conducteur.latitude, lng: conducteur.longitude }, // Initial position
                  driverId: conducteur.id, // Assuming there's a unique driver ID
                  userId: conducteur.user.id,
                  path: [],
                  type: conducteur.type,
                  icon: checkedIcon,
                  label: conducteur.name,
                  name: conducteur.user.personne.lastname + " " + conducteur.user.personne.firstname,
                  city: conducteur.user.personne.workPlaceName,
                  telephone: (conducteur.user.personne.tel && conducteur.user.personne.indicatifTel) ? conducteur.user.personne.indicatifTel + "  " + conducteur.user.personne.tel : 'Aucun nom trouvé',
                  // Other marker properties if needed
                };


                if (!this.markersLive.includes(marker.driverId)) {
                  this.markersLive.push(marker);
                }
                //console.log("all driver live", this.markersLive)

              }

            )


          }

        }

      } catch (error) {

        //console.log("erreur ", error)

      }

    },
    async init() {

      const response = await apiRequest('GET', 'courses-deplacement')
      console.log("Réponse du serveur pour les positions des conducteurs en ligne", response);
      if (response) {
        if (response.status == 200) {

          //console.log('driver encours', response.data)
          response.data.forEach(
            conducteur => {
              var checkedIcon = this.getIconForType(conducteur.moyenTransport);
              const marker = {
                position: conducteur.ligne.slice(Math.max(conducteur.ligne.length - 3, 0))[0], // Initial position
                driverId: conducteur.id, // Assuming there's a unique driver ID
                path: [],
                data: { label: conducteur.conducteur.personne.firstname },
                type: conducteur.moyenTransport,
                icon: checkedIcon,
              };
              if (!this.markersEncours.some(existingMarker => existingMarker.driverId === marker.driverId)) {
                this.markersEncours.push(marker);
                console.error('this.markersEncours1.push(marker);', this.markersEncours);
              }
            }
          )
        }
      }
    },

    async fetchDriverPositions() {
      try {
        const response = await apiRequest('GET', 'courses-deplacement');
        console.log("Réponse du serveur pour les positions des conducteurs en ligne 2", response);
        if (response && response.status === 200) {
          //console.log("Driver positions fetched:", response.data);
          const updatedMarkers = response.data.map(this.createMarkerFromConducteur);
          console.log(" fetchDriverPositions ", updatedMarkers)
          this.updateMarkerPositions(updatedMarkers);
        } else {
          console.error('Failed to fetch driver positions');
        }
      } catch (error) {
        console.error('Error fetching driver positions:', error);
      }
    },

    createMarkerFromConducteur(conducteur) {
      //console.log("conducteur",conducteur);
      const checkedIcon = this.getIconForType(conducteur.moyenTransport);
      const lastPosition = conducteur.ligne.slice(-1)[0];
      const newPosition = {
        lat: parseFloat(lastPosition.lat),
        lng: parseFloat(lastPosition.lng),
        head: lastPosition.head,
      };

      if (isNaN(newPosition.lat) || isNaN(newPosition.lng)) {
        console.error('Invalid position for conducteur:', conducteur.id);
        return null; // Retourner null pour les marqueurs invalides
      }

      return {
        position: newPosition,
        driverId: conducteur.id,
        path: conducteur.ligne.slice(-3).map(pos => ({
          lat: parseFloat(pos.lat),
          lng: parseFloat(pos.lng),
          head: pos.head,

        })),
        //head: conducteur.ligne.map(el => {value: el.head}),
        data: { label: conducteur.conducteur.personne.firstname },
        type: conducteur.moyenTransport,
        icon: checkedIcon,
        name: `${conducteur.conducteur.personne.lastname} ${conducteur.conducteur.personne.firstname}`,
        city: conducteur.conducteur.personne.workPlaceName,
        telephone: this.formatTelephone(conducteur.conducteur.personne),
      };
    },

    getIconForType(type) {
      const iconMap = {
        VIP: this.vipIcon,
        BASIC: this.basicIcon,
        CONFORT: this.comfortIcon,
        ZEM: this.zemIcon,
        TRICYCLE: this.tryIcon,
        // Gestion d'ECO
        ECO: this.ecoIcon,
      };
      return iconMap[type] || this.basicIcon;
    },

    formatTelephone(personne) {
      return (personne.tel && personne.indicatifTel)
        ? `${personne.indicatifTel} ${personne.tel}`
        : 'Aucun numéro trouvé';
    },

    updateMarkerPositions(updatedMarkers) {
      //console.log("Updating markers:", updatedMarkers);

      // Filtrer les marqueurs invalides
      const validUpdatedMarkers = updatedMarkers.filter(marker => marker !== null);

      // Mettre à jour les marqueurs existants et ajouter les nouveaux
      this.markersEncours = validUpdatedMarkers.map(updatedMarker => {

        const existingMarker = this.markersEncours.find(m => m.driverId === updatedMarker.driverId);

        if (existingMarker) {
          // Mettre à jour le marqueur existant
          return {
            ...existingMarker,
            ...updatedMarker,
            path: [...existingMarker.path, updatedMarker.position].slice(-3)
          };
        }


        // Ajouter un nouveau marqueur
        return updatedMarker;
      });
      //console.log("updatedMarker", updatedMarker);
      // Supprimer les marqueurs qui ne sont plus présents
      this.markersEncours = this.markersEncours.filter(marker =>
        validUpdatedMarkers.some(updatedMarker => updatedMarker.driverId === marker.driverId)
      );

      console.log("this.markersEncours", this.markersEncours);


      // Animer tous les marqueurs
      this.markersEncours.forEach(this.animateMarker);

      //console.log('Updated markersEncours:', this.markersEncours);
    },

    animateMarker(marker) {
      if (!marker || !marker.position || typeof marker.position.lat !== 'number' || typeof marker.position.lng !== 'number') {
        console.error('Invalid marker or position:', marker);
        return;
      }

      const newPosition = marker.position;
      const prevPosition = { ...marker.position }; // Copie de la position précédente
      console.log('Animating marker:', newPosition);

      // Créer un objet proxy pour l'animation
      const animationProxy = {
        x: prevPosition.lng,
        y: prevPosition.lat,
        h: prevPosition.head,
      };

      //console.log("this.$refs.carMarker+'-'+marker.driverId", this.$refs.carMarker+'-'+marker.driverId)
      const carElement = document.getElementById(`carMarker-${marker.driverId}`);
      console.log("animationProxy", animationProxy, marker.position.head);
      const debouncedUpdate =  debounce(() => {

        this.updateCarRotation(marker);

      }, 16);
      gsap.to(carElement, {
        duration: 2,
        x: newPosition.lng,
        y: newPosition.lat,
        rotation: newPosition.head,
        ease: 'power1.inOut',
        onStart: () => {
          this.updateCarRotation(marker);
        },


        onUpdate: () => {
          marker.position = {
            lat: animationProxy.y,
            lng: animationProxy.x,
            head: animationProxy.h,
          };
          debouncedUpdate();
        },
      });
    },

    updateCarRotation(marker) {
      console.log("updateCarRotation header", marker)
      if (!marker || marker.position.head === undefined) {
        console.error('Invalid marker or missing head:', marker);
        return;
      }
      const angle = marker.position.head;
      const carIconElement = document.getElementById(`carMarker-${marker.driverId}`);
      if (carIconElement) {
        carIconElement.style.transform = `rotate(${angle}deg)`;
      } else {
        console.error(`Car icon element not found for driver ${marker.driverId}.`);
      }
    },
  },
  beforeDestroy() {
    // Close the WebSocket connection when the component is destroyed
    if (this.socket) {
      this.socket.close();
      this.selectedRideId = null
    }
    clearInterval(this.fetchPositionsInterval);
    clearInterval(this.fetchLivePositionsInterval);
  },

  watch: {

  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="">

      <h4 class="ml-3"> <ion-icon name="analytics" style="color: red;"></ion-icon> Suivi des Assos</h4>

      <div class="row row-cols-4 justify-content-md-end p-4 position">
        <div class=" card col-3 mx-3 p-3 bg-white rounded text-right text-success">
          <div class="indicator bg-warning">

          </div>
          <div class="card-title">
            <ion-icon name="analytics" style="color: red;"></ion-icon>
            Course en cours

          </div>

          <div class="card-data">
            {{ markersEncours.length }}
          </div>

        </div>
        <div class=" card col-3 p-3 bg-white rounded text-right text-success">
          <div class="indicator bg-success">

          </div>
          <div class="card-title fs-3">
            Chauffeur en ligne

          </div>

          <div class="card-data">
            {{ markersLive.length }}
          </div>

        </div>

      </div>

    </div>


    <!-- {{ markersEncours }} -->
    <div class="row">
      <h4 class="ml-4 mb-3"> <span class="text-danger"><ion-icon name="map"></ion-icon></span> Cartographie</h4>
      <div class="col-lg-12">
        <div id="map" style="height:100%;">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <GmapMap :center="center" :zoom="zoom" map-type-id="terrain" style="width: 100%; height:800px">

                  <gmap-custom-marker v-for="marker in markersEncours" :key="marker.driverId" :marker="marker.position"
                    :clickable="true" :draggable="true">

                    <img :id="'carMarker-' + marker.driverId" :src="marker.icon.url" style="width: 30px; height: 57px "
                      :class="'carMarker-' + marker.driverId" alt="car-icon">

                    <GmapPolyline :key="`path-${marker.driverId}`" :path="marker.path"
                      :options="{ strokeColor: 'blue', strokeWeight: 3 }" />

                  </gmap-custom-marker>

                  <GmapMarker v-for="m in markersLive" :key="m.driverId" :position="m.position" :clickable="true"
                    :draggable="true" :icon="m.icon" @click="openMarker(m.driverId)">
                   
                    <GmapInfoWindow :closeclick="true" @closeclick="openMarker(null)"
                      :opened="openedMarkerID === m.driverId" @click="openMarker(m)">
                      <div class="card" style="width: 18rem">
                        <div class="card-body">
                          <h5 class="card-title">
                            <div>
                              <div class="" style="font-size: medium !important;">
                                <span>{{ m.name }} (en ligne)</span>
                              </div>
                            </div>
                          </h5>


                          <div class="mb-3">
                            <div class="mb-3">
                              Lieu d'activité:
                              <span>{{ m.city }}</span>
                            </div>
                            <div class="mb-3">
                              Téléphone: <a :href="'hhtps://wa.me/' + (m.telephone).replace(/\s+/g, '')"
                                target="_blank">{{
                                 m.telephone }}</a>
                            </div>

                            
                          </div>

                          <router-link :to="{ name: 'infoUserPage', params: { idUser: m.userId } }">
                            <a style="border-radius: 20px" href="#" class="btn btn-info w-80">
                              <i class="fa fa-eye fa-md text-center" aria-hidden="true"></i>&nbsp;
                              Profil</a>
                          </router-link>
                        </div>
                      </div>

                    </GmapInfoWindow>
                  </GmapMarker>

                </GmapMap>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- -->
  </Layout>
</template>

<style>
.position .card {
  position: relative;
  border: 0.5px solid rgba(45, 22, 22, 0.195);

}

.position .indicator {
  position: absolute;
  width: 30px;
  height: 30px;
  top: -8px;
}

.card-title {
  font-size: 160%;
}

.card-data {
  font-size: large;
  font-weight: bold;
}

.car-transition-enter-active,
.car-transition-leave-active {
  transition: transform 2s ease;
  /* Adjust duration as needed */
}

.car-transition-enter,
.car-transition-leave-to

/* .car-transition-leave-active in <2.1.8 */
  {
  transform: translateX(100%);
}
</style>
